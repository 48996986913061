import dynamic from "next/dynamic";
// Hero: (Non dynamic import) Above the fold content
import {
  Hero,
  Events,
  CtaBlock,
  About,
  Ticker,
  TextImage,
  Featured,
  TextBlock,
  Team,
  Clients,
  EventPackages,
  ServiceEvents,
  Services,
} from "components";
// Dynamic Imports: Below the fold content
import {
  FORM_BLOCK_FRAGMENT,
  FORM_SERVICE_BLOCK_FRAGMENT,
} from "components/Form/Form";
const Form = dynamic(() => import("components/Form/Form"), {
  ssr: true,
});

import {
  Page_Flexiblecontent_Blocks,
  Page_Flexiblecontent_Blocks_Hero,
  Page_Flexiblecontent_Blocks_Form,
  Page_Flexiblecontent_Blocks_Events,
  Page_Flexiblecontent_Blocks_About,
  Page_Flexiblecontent_Blocks_CtaBlock,
  Page_Flexiblecontent_Blocks_Featured,
  Page_Flexiblecontent_Blocks_Ticker,
  Page_Flexiblecontent_Blocks_TextImage,
  Page_Flexiblecontent_Blocks_TextBlock,
  Page_Flexiblecontent_Blocks_Team,
  Page_Flexiblecontent_Blocks_Clients,
  Page_Flexiblecontent_Blocks_EventPackages,
  Page_Flexiblecontent_Blocks_Services,
  Service_Flexiblecontent_Blocks,
  Service_Flexiblecontent_Blocks_Hero,
  Service_Flexiblecontent_Blocks_Form,
  Service_Flexiblecontent_Blocks_Events,
  Service_Flexiblecontent_Blocks_About,
  Service_Flexiblecontent_Blocks_CtaBlock,
  Service_Flexiblecontent_Blocks_Featured,
  Service_Flexiblecontent_Blocks_Ticker,
  Service_Flexiblecontent_Blocks_TextImage,
  Service_Flexiblecontent_Blocks_TextBlock,
  Service_Flexiblecontent_Blocks_Team,
  Service_Flexiblecontent_Blocks_Clients,
  Service_Flexiblecontent_Blocks_EventPackages,
  Service_Flexiblecontent_Blocks_Services,
} from "graphql";
import { gql } from "@apollo/client";

interface BlocksProps {
  blocks: (Page_Flexiblecontent_Blocks | Service_Flexiblecontent_Blocks)[];
}

interface BlockProps {
  block:
    | Page_Flexiblecontent_Blocks_Hero
    | Page_Flexiblecontent_Blocks_Form
    | Page_Flexiblecontent_Blocks_Events
    | Page_Flexiblecontent_Blocks_About
    | Page_Flexiblecontent_Blocks_CtaBlock
    | Page_Flexiblecontent_Blocks_Featured
    | Page_Flexiblecontent_Blocks_Ticker
    | Page_Flexiblecontent_Blocks_TextImage
    | Page_Flexiblecontent_Blocks_TextBlock
    | Page_Flexiblecontent_Blocks_Team
    | Page_Flexiblecontent_Blocks_Clients
    | Page_Flexiblecontent_Blocks_EventPackages
    | Page_Flexiblecontent_Blocks_Services
    | Service_Flexiblecontent_Blocks
    | Service_Flexiblecontent_Blocks_Hero
    | Service_Flexiblecontent_Blocks_Form
    | Service_Flexiblecontent_Blocks_Events
    | Service_Flexiblecontent_Blocks_About
    | Service_Flexiblecontent_Blocks_CtaBlock
    | Service_Flexiblecontent_Blocks_Featured
    | Service_Flexiblecontent_Blocks_Ticker
    | Service_Flexiblecontent_Blocks_TextImage
    | Service_Flexiblecontent_Blocks_TextBlock
    | Service_Flexiblecontent_Blocks_Team
    | Service_Flexiblecontent_Blocks_Clients
    | Service_Flexiblecontent_Blocks_EventPackages
    | Service_Flexiblecontent_Blocks_Services;
}

const prefixArr = [
  "Page_Flexiblecontent_Blocks_",
  "Service_Flexiblecontent_Blocks_",
];

const Block = ({ block }: BlockProps) => {
  const name = block && block.__typename;

  let component = name;
  const matchingPrefix = prefixArr.find((p) => component?.startsWith(p));
  if (matchingPrefix && component) {
    // @ts-ignore
    component = component?.substring(matchingPrefix.length);
  }

  switch (component) {
    // Hero
    case "Hero" as string: {
      return (
        <Hero
          {...(block as Page_Flexiblecontent_Blocks_Hero &
            Service_Flexiblecontent_Blocks_Hero)}
        />
      );
    }
    // Form
    case "Form" as string: {
      return (
        <Form
          {...(block as Page_Flexiblecontent_Blocks_Form &
            Service_Flexiblecontent_Blocks_Form)}
        />
      );
    }
    // Events
    case "Events" as string: {
      return (
        <Events
          {...(block as Page_Flexiblecontent_Blocks_Events &
            Service_Flexiblecontent_Blocks_Events)}
        />
      );
    }
    // Cta Block
    case "CtaBlock" as string: {
      return (
        <CtaBlock
          {...(block as Page_Flexiblecontent_Blocks_CtaBlock &
            Service_Flexiblecontent_Blocks_CtaBlock)}
        />
      );
    }
    // About
    case "About" as string: {
      return (
        <About
          {...(block as Page_Flexiblecontent_Blocks_About &
            Service_Flexiblecontent_Blocks_About)}
        />
      );
    }
    // Ticker
    case "Ticker" as string: {
      return (
        <Ticker
          {...(block as Page_Flexiblecontent_Blocks_Ticker &
            Service_Flexiblecontent_Blocks_Ticker)}
        />
      );
    }
    // Text Image
    case "TextImage" as string: {
      return (
        <TextImage
          {...(block as Page_Flexiblecontent_Blocks_TextImage &
            Service_Flexiblecontent_Blocks_TextImage)}
        />
      );
    }
    // Featured
    case "Featured" as string: {
      return (
        <Featured
          {...(block as Page_Flexiblecontent_Blocks_Featured &
            Service_Flexiblecontent_Blocks_Featured)}
        />
      );
    }
    // Text Block
    case "TextBlock" as string: {
      return (
        <TextBlock
          {...(block as Page_Flexiblecontent_Blocks_TextBlock &
            Service_Flexiblecontent_Blocks_TextBlock)}
        />
      );
    }
    // Team
    case "Team" as string: {
      return (
        <Team
          {...(block as Page_Flexiblecontent_Blocks_Team &
            Service_Flexiblecontent_Blocks_Team)}
        />
      );
    }
    case "Clients" as string: {
      return (
        <Clients
          {...(block as Page_Flexiblecontent_Blocks_Clients &
            Service_Flexiblecontent_Blocks_Clients)}
        />
      );
    }
    case "EventPackages" as string: {
      return (
        <EventPackages
          {...(block as Page_Flexiblecontent_Blocks_EventPackages &
            Service_Flexiblecontent_Blocks_EventPackages)}
        />
      );
    }
    case "ServiceEvents" as string: {
      return <ServiceEvents {...(block as any)} />;
    }
    case "Services" as string: {
      return <Services {...(block as any)} />;
    }
    default: {
      return null;
    }
  }
};

const Blocks = ({ blocks }: BlocksProps): JSX.Element => {
  return (
    <>
      {blocks &&
        blocks.map((block, index) => (
          <Block block={block as any} key={`block-${index}`} />
        ))}
    </>
  );
};

export default Blocks;

Blocks.fragments = {
  pageEntry: gql`
    fragment BlocksPageFragment on Page_Flexiblecontent {
      blocks {
        ... on Page_Flexiblecontent_Blocks_Hero {
          ...HeroPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_Form {
          ...FormPageBlockFragment
        }
        ... on Page_Flexiblecontent_Blocks_Events {
          ...EventsPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_CtaBlock {
          ...CtaBlockPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_About {
          ...AboutPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_Ticker {
          ...TickerPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_TextImage {
          ...TextImagePageFragment
        }
        ... on Page_Flexiblecontent_Blocks_Featured {
          ...FeaturedPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_TextBlock {
          ...TextBlockPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_Team {
          ...TeamPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_Clients {
          ...ClientsPageFragment
        }
        ... on Page_Flexiblecontent_Blocks_EventPackages {
          ...EventPackagesPageFragment
        }
      }
    }
    ${Hero.fragments.pageEntry}
    ${Events.fragments.pageEntry}
    ${CtaBlock.fragments.pageEntry}
    ${About.fragments.pageEntry}
    ${Ticker.fragments.pageEntry}
    ${TextImage.fragments.pageEntry}
    ${Featured.fragments.pageEntry}
    ${TextBlock.fragments.pageEntry}
    ${Team.fragments.pageEntry}
    ${Clients.fragments.pageEntry}
    ${EventPackages.fragments.pageEntry}
    ${FORM_BLOCK_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment BlocksServiceFragment on Service_Flexiblecontent {
      blocks {
        ... on Service_Flexiblecontent_Blocks_Hero {
          ...HeroServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_Form {
          ...FormServiceBlockFragment
        }
        ... on Service_Flexiblecontent_Blocks_Events {
          ...EventsServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_CtaBlock {
          ...CtaBlockServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_About {
          ...AboutServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_Ticker {
          ...TickerServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_TextImage {
          ...TextImageServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_Featured {
          ...FeaturedServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_TextBlock {
          ...TextBlockServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_Team {
          ...TeamServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_Clients {
          ...ClientsServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_EventPackages {
          ...EventPackagesServiceFragment
        }
        ... on Service_Flexiblecontent_Blocks_Services {
          ...ServicesServiceFragment
        }
      }
    }
    ${Hero.fragments.serviceEntry}
    ${Events.fragments.serviceEntry}
    ${CtaBlock.fragments.serviceEntry}
    ${About.fragments.serviceEntry}
    ${Ticker.fragments.serviceEntry}
    ${TextImage.fragments.serviceEntry}
    ${Featured.fragments.serviceEntry}
    ${TextBlock.fragments.serviceEntry}
    ${Team.fragments.serviceEntry}
    ${Clients.fragments.serviceEntry}
    ${EventPackages.fragments.serviceEntry}
    ${Services.fragments.serviceEntry}
    ${FORM_SERVICE_BLOCK_FRAGMENT}
  `,
};
