import React from "react";
import Image from "next/image";
import { Button } from "components/Button";

interface ContactCtaProps {
  className?: string;
}

const ContactCta = ({ className }: ContactCtaProps) => {
  return (
    <section className={`${className ? className : ""}`}>
      <div className="mx-auto flex max-w-[1500px] flex-col md:flex-row items-center pt-10 pb-10 md:pb-0 md:py-10">
        <div className="w-full md:w-1/2 lg:w-2/5 px-10 lg:px-20 py-0 md:py-10">
          <div className="relative">
            <h2
              className={`mb-10 max-w-[340px] font-serif text-[2.5rem] font-semibold leading-none text-center md:text-left`}
            >
              READY <span className={`font-normal italic`}>for the</span> NINTH
              FLOOR <span className={`font-normal italic`}>experience?</span>
            </h2>
            <Button
              type={`primary`}
              className={`flex w-fit flex-row items-center lg:border-[1px] lg:border-black text-[1rem] lg:py-2 lg:px-6 lg:hover:border-primary lg:hover:bg-primary lg:hover:text-black mx-auto md:mx-0`}
              href={`/contact-us`}
            >
              <Image
                src={`https://cms.ninthfloorevents.com/wp-content/uploads/2023/09/phone_ico.svg`}
                alt={`Contact Us`}
                width={22}
                height={22}
                className={`mr-4 w-4`}
              />
              Contact Us
            </Button>
          </div>
        </div>
        <div className="hidden md:block w-1/2 lg:w-3/5 rounded-l-full border-[1px] border-r-0 border-black p-10 pr-0">
          <div className="relative h-full min-h-[25rem] w-full overflow-hidden rounded-l-full">
            <Image
              src={`/contact-img.png`}
              alt={`Contact Us`}
              fill
              className={`object-cover object-center`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactCta;
