import { useQuery, gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Featured } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Content } from "components/Content";
import Image from "next/image";

interface FeaturedProps extends Page_Flexiblecontent_Blocks_Featured {
  className?: string;
}

const LATEST_POSTS_QUERY = gql`
  query GetLatestPosts {
    posts(first: 3) {
      nodes {
        id
        title
        uri
        slug
        date
        featuredImage {
          node {
            sourceUrl
            altText
            caption
            description
            srcSet
            sizes
            id
            caption
            mediaDetails {
              width
              height
            }
            mimeType
          }
        }
      }
    }
  }
`;

const Featured = ({ className, title, feedType, items }: FeaturedProps) => {
  const {
    data: postData,
    loading: postLoading,
    error: postError,
  } = useQuery(LATEST_POSTS_QUERY);

  const latestPosts = postData?.posts?.nodes || [];

  return (
    <div className={`${className} bg-light-gray`}>
      <div className="py-8">
        <h2 className="mb-4 text-center font-serif text-[2.07rem] font-semibold tracking-[0.01rem]">
          <Content content={title} />
        </h2>
        <hr className={`border-black opacity-50`} />
        <div className="mx-auto mt-6 max-w-[1500px]">
          {feedType === "items" && items && (
            <div className="flex flex-wrap justify-center">
              {/* {items.map((item, index) => (
              <div
                key={index}
                className="w-full p-4 sm:w-1/2 md:w-1/3 lg:w-1/4"
              >
                {item?.post?.featuredImage && (
                  <div className="overflow-hidden rounded-full">
                    <img
                      className="w-full"
                      src={item?.post?.featuredImage?.node?.sourceUrl}
                      alt={item?.post?.featuredImage?.node?.altText}
                    />
                  </div>
                )}
                <div className="px-6 py-4">
                  <p className="text-base text-gray-700">{item?.post?.date}</p>
                  <div className="mb-2 text-xl font-bold">
                    {item?.post?.title}
                  </div>
                </div>
                <div className="px-6 pb-2 pt-4">
                  <a
                    href={item?.post?.uri}
                    className="mb-2 mr-2 inline-block rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700"
                  >
                    Read More
                  </a>
                </div>
              </div>
            ))} */}
            </div>
          )}
          {feedType === "latest" && (
            <div className="flex flex-col lg:flex-row flex-wrap justify-center px-10 xl:px-40">
              {latestPosts.map((post: any, index: number) => {
                const prettyDate = new Date(post.date).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  },
                );
                return (
                  <div
                    key={index}
                    className="w-full p-4 flex-1 flex flex-col items-center lg:items-start"
                  >
                    {post?.featuredImage?.node && (
                      <div className="relative w-[13.2rem] h-[13.2rem] overflow-hidden rounded-full">
                        <Image
                          className="h-full w-full object-cover"
                          fill
                          src={post.featuredImage.node.sourceUrl}
                          alt={post.featuredImage.node.altText}
                        />
                      </div>
                    )}
                    <div className="px-6 py-4">
                      <p className="text-[1.1rem] mt-4 mb-4">{prettyDate}</p>
                      <div className="mb-0 text-[1.15rem] font-bold max-w-[250px]">
                        {post.title}
                      </div>
                    </div>
                    <div className="px-4 pb-2">
                      <a
                        href={post.uri}
                        className="group text-md mb-2 mr-2 flex flex-row px-3 py-1 font-sans font-bold text-[0.95rem]"
                      >
                        Read More{" "}
                        <Image
                          src="/right-arrow.svg"
                          width={22}
                          height={22}
                          alt="Read More"
                          className="ml-4 mt-0 group-hover:ml-6 transition-all duration-300 ease-in-out"
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Featured;

Featured.fragments = {
  pageEntry: gql`
    fragment FeaturedPageFragment on Page_Flexiblecontent_Blocks_Featured {
      __typename
      feedType
      fieldGroupName
      items {
        post {
          ... on Post {
            id
            title
            uri
            slug
            date
            featuredImage {
              node {
                ...MediaItemFragment
              }
            }
          }
        }
      }
      title
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment FeaturedServiceFragment on Service_Flexiblecontent_Blocks_Featured {
      __typename
      feedType
      fieldGroupName
      items {
        post {
          ... on Post {
            id
            title
            uri
            slug
            date
            featuredImage {
              node {
                ...MediaItemFragment
              }
            }
          }
        }
      }
      title
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
