import Link from "next/link";

interface ButtonProps {
  href?: string;
  children: React.ReactNode;
  className?: string;
  type: "primary" | "secondary" | "secondary-outline";
  onClick?: (e: any) => void;
  disabled?: boolean;
  target?: string;
}

const styleLoader = (type: string) => {
  switch (type) {
    case "primary":
      return `rounded-full text-[1.05rem] border-black border-2 bg-white py-2 px-6 text-black font-sans transition duration-300 ease-in-out hover:shadow-lg hover:border-black hover:bg-primary hover:text-black`;
    case "secondary":
      return `rounded-full bg-white py-2 px-4 font-bold text-primary font-sans uppercase hover:bg-primary hover:text-white transition duration-300 ease-in-out`;
    case "secondary-outline":
      return `bg-white rounded-full border-2 border-primary py-2 px-4 font-bold text-primary font-sans uppercase hover:bg-primary hover:text-white hover:border-primary transition duration-300 ease-in-out`;
    default:
      return `rounded-full bg-primary py-2 px-4 font-bold text-white font-sans uppercase`;
  }
};

const Button = ({
  href,
  target,
  children,
  className,
  type,
  disabled,
  ...props
}: ButtonProps) => {
  return (
    <Link
      className={`${styleLoader(type)} ${className}`}
      role="button"
      href={href ?? ``}
      target={target}
      {...props}
    >
      {children}
    </Link>
  );
};

export default Button;
