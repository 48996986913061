import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { NavigationMenu } from "components/NavigationMenu";
import { AcfLink, Acf_GoogleMap, MediaItem, MenuItem } from "graphql";
import Link from "next/link";
import Logo from "public/footer_logo.svg";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import Image from "next/image";

export interface FooterProps {
  menuItems: MenuItem[];
  phoneNumber: AcfLink;
  address: Acf_GoogleMap;
  email: AcfLink;
  cta: AcfLink;
  ctaIcon: MediaItem;
}

const Footer = ({
  menuItems,
  phoneNumber,
  address,
  email,
  cta,
  ctaIcon,
}: FooterProps) => {
  const { sourceUrl, altText, mediaDetails } = ctaIcon;

  const linkClass = `font-sans text-[.9rem] font-bold uppercase tracking-[0.25rem] text-black transition-colors duration-300 hover:text-primary`;

  return (
    <footer className={`bg-white px-10 md:pt-14 font-sans text-gray-100`}>
      <div
        className={`mx-auto flex max-w-[1500px] flex-col flex-wrap border-t-[1px] border-gray-200 lg:px-10 py-10 md:flex-row md:justify-between`}
      >
        <div className={`flex w-full md:w-1/2 flex-col`}>
          {/* Logo */}
          <div className={`relative w-[198px] h-[104px]`}>
            <Link href={`/`}>
              <Image
                src="/9thFLoor_logo_horz_blk.png"
                alt="Ninth Floor Events"
                fill
                className={`w-full h-full object-contain`}
              />
            </Link>
          </div>
          <ul className="mt-7 flex flex-row">
            <li className="mx-3">
              <a
                href="https://www.instagram.com/ninthfloorevents/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram className="text-3xl text-black transition-colors duration-200 hover:text-primary" />
              </a>
            </li>
            <li className="mx-3">
              <a
                href="https://www.facebook.com/ninthfloorevents/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF className="text-3xl text-black transition-colors duration-200 hover:text-primary" />
              </a>
            </li>
            <li className="mx-3">
              <a
                href="https://twitter.com/9thfloorevents"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="text-3xl text-black transition-colors duration-200 hover:text-primary" />
              </a>
            </li>
            <li className="mx-3">
              <a
                href="https://www.linkedin.com/company/ninth-floor-events/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsLinkedin className="text-3xl text-black transition-colors duration-200 hover:text-primary" />
              </a>
            </li>
          </ul>
          <p className="mt-10 text-[.8rem] text-black">
            210.298.2677 • 7550 W Interstate 10 • Ste. 960 • San Antonio, TX
            78229
            <br />
            Copyright Ninth Floor Events &copy; 2023
          </p>
        </div>
        <div className={`w-full py-10 md:py-0 md:w-1/4`}>
          <ul className={`flex flex-col`}>
            <li className={`my-5`}>
              <Link className={linkClass} href={`/about`}>
                About
              </Link>
            </li>
            <li className={`my-5`}>
              <Link className={linkClass} href={`/services/corporate-events`}>
                Corporate Events
              </Link>
            </li>
            <li className={`my-5`}>
              <Link className={linkClass} href={`/services/non-profit-events`}>
                Non-Profit Events
              </Link>
            </li>
            <li className={`my-5`}>
              <Link className={linkClass} href={`/services/private-events`}>
                Private Events
              </Link>
            </li>
            <li className={`my-5`}>
              <Link className={linkClass} href={`/services/virtual-events`}>
                Virtual Events
              </Link>
            </li>

            {/* <li className={`my-5`}>
              <Link
                className={linkClass}
                href={`/our-work`}
              >
                Our Work
              </Link>
            </li>

            <li className={`my-5`}>
              <Link
                className={linkClass}
                href={`/blog`}
              >
                Blog
              </Link>
            </li> */}
          </ul>
        </div>
        <div className={`flex w-full md:w-1/4 flex-col justify-center`}>
          <h2
            className={`mb-5 font-serif text-[2.5rem] font-semibold tracking-wide text-black leading-none`}
          >
            <span className={`font-normal italic`}>Let’s get</span>
            <br />
            STARTED
          </h2>
          {cta ? (
            <Button
              type={`primary`}
              className={`flex w-fit flex-row items-center text-[1rem] lg:border-[1px] lg:border-black lg:px-6 lg:py-2`}
              href={cta.url ?? `/contact-us`}
            >
              {ctaIcon ? (
                <Image
                  src={sourceUrl || ``}
                  alt={altText || ``}
                  width={mediaDetails?.width ?? 0}
                  height={mediaDetails?.height ?? 0}
                  className={`mr-4 w-3`}
                />
              ) : null}
              {cta.title}
            </Button>
          ) : null}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
