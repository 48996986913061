import { gql } from "@apollo/client";
import { Content } from "components/Content";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Page_Flexiblecontent_Blocks_Team } from "graphql";
import Image from "next/image";

interface TeamProps extends Page_Flexiblecontent_Blocks_Team {
  className?: string;
}

const Team = ({ className, title, teamMembers }: TeamProps) => {
  return (
    <section
      className={`${className} bg-black text-white relative overflow-hidden`}
    >
      <div className="mx-auto max-w-[1500px] px-10 py-20 md:py-14">
        <h2
          className={`mb-12 text-center font-serif text-[2.55rem] font-semibold`}
        >
          <Content content={title} />
        </h2>
        <div
          className={`mx-auto grid max-w-xl lg:max-w-[54rem] xl:max-w-[62rem] grid-cols-2 md:grid-cols-3 gap-10 md:gap-0 xl:gap-8`}
        >
          {teamMembers?.map((member, index) => (
            <div key={index} className={`flex flex-col items-center lg:mt-4`}>
              <Image
                className={`mb-4 w-26 h-26 md:w-40 md:h-40 lg:w-60 lg:h-60 xl:h-[269px] xl:w-[269px] rounded-full object-cover`}
                src={member?.photo?.sourceUrl ?? ""}
                width={member?.photo?.mediaDetails?.width ?? 0}
                height={member?.photo?.mediaDetails?.height ?? 0}
                alt={member?.photo?.altText ?? ""}
              />
              <h3
                className={`text-[.9rem] tracking-[0.25rem] font-bold uppercase text-center`}
              >
                <Content content={member?.name} />
              </h3>
              <div
                className={`text-center uppercase text-[.75rem] md:text-[.9rem] tracking-[0.10rem] md:tracking-[0.25rem]`}
              >
                <Content content={member?.title} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`h-[86px] md:h-[160px] w-full absolute -top-[43px] left-0 md:top-[400px] md:-left-[50%] bg-[url('/Large_Border_Graphic.svg')] md:bg-repeat-x rotate-180 md:rotate-90`}
      ></div>
      <div
        className={`h-[86px] md:h-[160px] w-full absolute -bottom-[43px] left-0 md:left-auto md:top-[400px] md:-right-[50%] bg-[url('/Large_Border_Graphic.svg')] bg-repeat-x -rotate-180 md:-rotate-90 -scale-y-100 md:scale-y-100`}
      ></div>
    </section>
  );
};

export default Team;

Team.fragments = {
  pageEntry: gql`
    fragment TeamPageFragment on Page_Flexiblecontent_Blocks_Team {
      __typename
      title
      teamMembers {
        name
        title
        photo {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment TeamServiceFragment on Service_Flexiblecontent_Blocks_Team {
      __typename
      title
      teamMembers {
        name
        title
        photo {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
