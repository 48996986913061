import { useQuery, gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout, Blocks } from "features";
import { NavigationMenu } from "components";
import { ContactCta } from "components/ContactCta";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
} from "fragments";

export default function Component(props) {
  const { data, loading, error } = props;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { service, headerMenuItems, footerMenuItems, siteSettings } = data;
  const { seo, title, flexibleContent, serviceEvents } = service;
  const { blocks } = flexibleContent;
  const {
    address,
    customAddressLabel,
    phoneNumber,
    logo,
    logoWhite,
    logoAlt,
    cta,
    ctaIcon,
    email,
  } = siteSettings.siteSettings;

  const serviceBlocks = [...blocks];

  const eventPackagesBlockIndex = serviceBlocks.findIndex(
    (block) =>
      block.__typename === "Service_Flexiblecontent_Blocks_EventPackages",
  );

  const serviceBlockIndex = serviceBlocks.findIndex(
    (block) => block.__typename === "Service_Flexiblecontent_Blocks_Services",
  );

  const serviceBlock = serviceBlocks[serviceBlockIndex];

  if (eventPackagesBlockIndex > -1 && serviceBlock) {
    // Remove original service block
    if (serviceBlockIndex > -1) {
      serviceBlocks.splice(serviceBlockIndex, 1);
    }
    // insert service block before event packages block
    serviceBlocks.splice(eventPackagesBlockIndex, 0, serviceBlock);
  } else {
    // Insert serviceEvents before the event packages block
    serviceBlocks.splice(eventPackagesBlockIndex, 0, {
      __typename: "Service_Flexiblecontent_Blocks_ServiceEvents",
      serviceEvents,
    });
  }

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo}
      logoWhite={logoWhite}
      logoAlt={logoAlt}
      cta={cta}
      ctaIcon={ctaIcon}
    >
      <Blocks blocks={serviceBlocks} />

      <ContactCta />
    </Layout>
  );
}

Component.query = gql`
  query GetServiceData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
    $asPreview: Boolean = false
  ) {
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }

    service(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      title
      seo {
        ...SEOFragment
      }
      flexibleContent {
        ...BlocksServiceFragment
      }
      serviceEvents {
        nodes {
          name
          categoryOptions {
            categoryImage {
              ...MediaItemFragment
            }
          }
        }
      }
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${NavigationMenu.fragments.entry}
  ${Blocks.fragments.serviceEntry}
  ${SEO_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
