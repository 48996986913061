import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { NavigationMenu } from "components/NavigationMenu";
import { useCycle, useScroll } from "framer-motion";
import { AcfLink, MediaItem, MenuItem } from "graphql";
import { useEffect, useRef, useState } from "react";
import flatListToHierarchical from "utilities/flatListToHierarchical";
import Image from "next/image";
import Link from "next/link";

export interface HeaderProps {
  menuItems: MenuItem[];
  logo: MediaItem;
  logoAlt: MediaItem;
  cta: AcfLink;
  ctaIcon: MediaItem;
}

const Header = ({ menuItems, logo, logoAlt, cta, ctaIcon }: HeaderProps) => {
  const [open, cycleOpen] = useCycle(false, true);
  const ref = useRef(null);
  const { scrollY } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });
  const [isScrolledPast, setIsScrolledPast] = useState(false);

  const { sourceUrl, altText, mediaDetails } = ctaIcon;

  useEffect(() => {
    return scrollY.onChange((latest) => {
      // If latest is greater than height of header, set isScrolledPast to true
      if (latest > 0) {
        setIsScrolledPast(true);
      } else {
        setIsScrolledPast(false);
      }
    });
  }, [scrollY]);

  return (
    <header
      className={`${
        isScrolledPast ? `shadow-md` : ``
      } body-font sticky top-0 z-30 max-h-fit bg-black font-sans text-black transition-shadow`}
      ref={ref}
    >
      <div className="mx-auto flex max-w-[1500px] flex-row flex-wrap items-center justify-end lg:justify-between py-6 lg:py-0 px-5 md:px-10 relative">
        <div
          className={`${
            open
              ? `absolute top-[15%] left-1/2 -translate-x-1/2 -ml-[11px]`
              : `hidden`
          } lg:flex flex-row flex-wrap items-center justify-center lg:justify-start py-2`}
        >
          {/* Logo */}
          {logo && (
            <Link href="/">
              <FeaturedImage image={logo} className={`mb-4 w-[8rem] md:mb-0`} />
            </Link>
          )}
        </div>
        <div className={`w-full lg:w-fit lg:flex lg:self-stretch`}>
          {/* Navigation Menu */}
          <NavigationMenu
            menuItems={flatListToHierarchical(menuItems)}
            isOpen={open}
            type="secondary"
            toggleOpen={() => cycleOpen()}
          />
          {/* CTA */}
          <div
            className={`hidden lg:flex flex-row flex-wrap items-center justify-center self-stretch`}
          >
            {cta ? (
              <Button
                type={`primary`}
                className={`${
                  open ? `absolute top-6 right-10` : ``
                } lg:relative lg:top-auto lg:right-auto ml-4 flex flex-row items-center text-[1rem] lg:py-2 lg:px-7`}
                href={cta.url ?? `/contact-us`}
              >
                {ctaIcon ? (
                  <Image
                    src={sourceUrl || ``}
                    alt={altText || ``}
                    width={mediaDetails?.width ?? 0}
                    height={mediaDetails?.height ?? 0}
                    className={`mr-4 w-3`}
                  />
                ) : null}
                {cta.title}
              </Button>
            ) : null}
          </div>
        </div>
        {/* CTA */}
        <div
          className={`flex lg:hidden flex-row flex-wrap items-center justify-center self-stretch`}
        >
          {cta ? (
            <Button
              type={`primary`}
              className={`${
                open ? `absolute top-6 right-5` : ``
              } lg:relative lg:top-auto lg:right-auto ml-4 flex flex-row items-center text-[1rem] lg:py-2 lg:px-7`}
              href={cta.url ?? `/contact-us`}
            >
              {ctaIcon ? (
                <Image
                  src={sourceUrl || ``}
                  alt={altText || ``}
                  width={mediaDetails?.width ?? 0}
                  height={mediaDetails?.height ?? 0}
                  className={`mr-4 w-3`}
                />
              ) : null}
              {cta.title}
            </Button>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
