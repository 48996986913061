import { gql } from "@apollo/client";
import {
  Page_Flexiblecontent_Blocks_Clients,
  Service_Flexiblecontent_Blocks_Clients,
} from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Content } from "components/Content";
import Image from "next/image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./Clients.module.css";
import { ButtonGroupProps, ArrowProps } from "react-multi-carousel/lib/types";
import Balancer from "react-wrap-balancer";

type ClientsProps = (Page_Flexiblecontent_Blocks_Clients &
  Service_Flexiblecontent_Blocks_Clients) & {
  className?: string;
};
interface CustomLeftArrowProps extends ArrowProps {
  className?: string;
}
interface CustomRightArrowProps extends ArrowProps {
  className?: string;
}
interface CarouselButtonGroupProps extends ButtonGroupProps {
  className?: string;
  next?: () => void;
  previous?: () => void;
}

const Clients = ({
  className,
  quote,
  quoteAuthor,
  image,
  clientsServed,
}: ClientsProps) => {
  const { sourceUrl, altText } = image ?? {};
  const hasClients = Array.isArray(clientsServed) && clientsServed.length > 0;

  const carouselResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 3,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const CustomLeftArrow = ({ className, onClick }: CustomLeftArrowProps) => {
    return (
      <button className={className} onClick={onClick}>
        <Image src="/right-arrow.svg" width={22} height={22} alt="Read More" />
      </button>
    );
  };
  const CustomRightArrow = ({ className, onClick }: CustomRightArrowProps) => {
    return (
      <button className={className} onClick={onClick}>
        <Image src="/right-arrow.svg" width={22} height={22} alt="Read More" />
      </button>
    );
  };

  const ButtonGroup = ({
    next,
    previous,
    carouselState,
  }: CarouselButtonGroupProps) => {
    const { currentSlide } = carouselState ?? {};
    const lastPage = (clientsServed?.length || 0) - 5;
    return (
      <div className={`carousel-button-group lg:hidden`}>
        <CustomLeftArrow
          className={`absolute left-0 top-1/2 -translate-y-1/2 -ml-5 lg:-ml-10 transition-all duration-300 ease-in-out rotate-180`}
          onClick={() => previous?.()}
        />
        <CustomRightArrow
          className={`absolute right-0 top-1/2 -translate-y-1/2 -mr-5 lg:-mr-10 transition-all duration-300 ease-in-out`}
          onClick={() => next?.()}
        />
      </div>
    );
  };

  return (
    <>
      <section className={`${className ? className : ""}`}>
        <div className="mx-auto flex max-w-[1500px] flex-col md:flex-row py-0 lg:py-10">
          <div className="hidden md:block w-2/5 xl:w-3/5 rounded-r-full border-[1px] border-l-0 border-black p-10 pl-0 self-center">
            <div className="relative h-[325px] w-full overflow-hidden rounded-r-full">
              <Image
                src={sourceUrl ?? ``}
                // width={mediaDetails?.width ?? 0}
                // height={mediaDetails?.height ?? 0}
                alt={altText ?? ``}
                fill
                className={`object-cover object-center`}
              />
            </div>
          </div>
          <div className="w-full md:w-3/5 xl:w-2/5 px-10 md:px-20 md:pr-10 py-10">
            {hasClients && (
              <>
                <h3 className={`uppercase text-[.95rem] font-bold mb-6`}>
                  Clients Served
                </h3>
                <div className={styles.carousel}>
                  <Carousel
                    responsive={carouselResponsive}
                    showDots={true}
                    draggable={false}
                    // swipeable={false}
                    // ssr={true}
                    renderDotsOutside={true}
                    arrows={false}
                    customButtonGroup={<ButtonGroup />}
                    renderButtonGroupOutside={true}
                    itemClass={`px-4 min-h-[5rem]`}
                  >
                    {clientsServed?.map((client, index) => {
                      return (
                        <div
                          key={index}
                          className="relative flex h-full w-full flex-row items-center"
                        >
                          <Image
                            src={client?.logo?.sourceUrl ?? ``}
                            // width={mediaDetails?.width ?? 0}
                            // height={mediaDetails?.height ?? 0}
                            alt={client?.logo?.altText ?? ``}
                            fill
                            className={`object-contain object-center`}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </>
            )}
            <div className="relative">
              <svg
                fill="none"
                height="85"
                viewBox="0 0 121 105"
                width="101"
                xmlns="http://www.w3.org/2000/svg"
                className={`absolute left-0 top-0 -translate-x-1/2 -translate-y-1/2 transform`}
              >
                <path
                  d="m2.945 37.2024-.00018-.0001-.0031.0133c-1.62878 6.9615-2.44172 14.4523-2.44172 22.4705v21.8496c0 12.6873 10.4406 22.9643 23.119 22.9643 12.5086 0 22.8096-10.1394 22.8096-22.657v-10.2165c0-6.8707-5.5698-12.4404-12.4405-12.4404-6.3341 0-11.4714-5.1381-10.819-11.3667.0571-.5452.1181-1.0904.1831-1.6357.8027-4.744 2.1374-8.949 3.9985-12.6204 1.8579-3.6649 4.237-6.5267 7.1312-8.6027l.0115-.0083.0111-.0089c.5402-.4359 1.1079-.8307 1.7034-1.1845 2.3897-1.4197 4.8328-3.1601 6.6827-5.2625 1.8535-2.1063 3.1332-4.6023 3.1332-7.5158 0-5.74553-4.7432-10.718104-10.5158-9.43726-3.6255.80442-6.9807 2.07454-10.063 3.81179-5.7311 3.2301-10.5087 7.53847-14.3289 12.92137l-.005.0071-.0048.0072c-3.54234 5.3752-6.26082 11.6814-8.1613 18.9116zm74.0714 0-.0001-.0001-.0031.0133c-1.6288 6.9615-2.4418 14.4522-2.4418 22.4705v21.8496c0 12.6873 10.4407 22.9643 23.119 22.9643 12.5086 0 22.8096-10.1394 22.8096-22.657v-10.2165c0-6.8707-5.57-12.4404-12.44-12.4404-6.335 0-11.4719-5.1381-10.8195-11.3667.0571-.5452.1181-1.0905.1831-1.6357.8027-4.744 2.1374-8.949 3.9984-12.6204 1.858-3.6649 4.237-6.5267 7.131-8.6027l.012-.0083.011-.0089c.54-.4359 1.108-.8307 1.703-1.1845 2.39-1.4197 4.833-3.1601 6.683-5.2625 1.853-2.1063 3.133-4.6023 3.133-7.5158 0-5.74553-4.743-10.718103-10.516-9.43726-3.625.80442-6.98 2.07454-10.0626 3.81179-5.7311 3.2301-10.5086 7.53847-14.3289 12.92137l-.005.0071-.0048.0072c-3.5423 5.3752-6.2608 11.6814-8.1613 18.9116z"
                  stroke="#fed101"
                />
              </svg>
              <Balancer>
                <Content
                  className="text-[1.3rem] md:text-[1.6rem] italic leading-relaxed tracking-[.15rem] md:tracking-[.26rem]"
                  content={quote}
                />
              </Balancer>
              <svg
                fill="none"
                height="71"
                viewBox="0 0 82 71"
                width="62"
                xmlns="http://www.w3.org/2000/svg"
                className={`absolute -bottom-2 right-0`}
              >
                <path
                  d="m79.8508 45.7903.0002.0001.0031-.0133c1.0982-4.6774 1.6459-9.7084 1.6459-15.0911v-14.5839c0-8.6224-7.1215-15.602093-15.7327-15.602094-8.4681 0-15.4714 6.863714-15.4714 15.342994v6.8158c0 4.7095 3.8178 8.5272 8.5272 8.5272 4.173 0 7.5376 3.3816 7.1074 7.46-.0373.3538-.0771.7076-.1194 1.0615-.5373 3.1624-1.4297 5.9616-2.6717 8.403-1.2386 2.4348-2.8217 4.3303-4.7431 5.7037l-.0115.0082-.0111.0089c-.3624.2914-.7436.555-1.1437.7907-1.6117.9495-3.2664 2.1231-4.5224 3.549-1.2595 1.4299-2.1396 3.1393-2.1396 5.143 0 3.9264 3.2486 7.3684 7.2454 6.4883 2.4609-.5419 4.7394-1.3999 6.8328-2.5757 3.8739-2.1756 7.1039-5.0782 9.6862-8.704l.005-.007.0048-.0072c2.3929-3.6182 4.2271-7.8599 5.5086-12.7181zm-49.7959 0 .0002.0001.0031-.0133c1.0982-4.6774 1.6459-9.7084 1.6459-15.0911v-14.5839c0-8.62241-7.1215-15.602097-15.7327-15.602098-8.46809-.000001-15.471402 6.863718-15.471403 15.342998v6.8158c-.000001 4.7095 3.817753 8.5272 8.527203 8.5272 4.173 0 7.5376 3.3816 7.1074 7.46l.4973.0524-.4973-.0524c-.0373.3538-.0771.7076-.1194 1.0615-.5373 3.1624-1.4298 5.9616-2.6717 8.403-1.2387 2.4347-2.8217 4.3302-4.74311 5.7037l-.01153.0082-.01105.0089c-.36244.2914-.74365.555-1.14377.7907-1.6117.9495-3.26634 2.1231-4.52236 3.549-1.25949 1.4299-2.139585 3.1393-2.139585 5.1429 0 3.9265 3.248595 7.3685 7.245365 6.4884 2.46094-.5419 4.73934-1.3999 6.83284-2.5757 3.8739-2.1756 7.1039-5.0782 9.6862-8.704l.005-.007.0048-.0072c2.3928-3.6182 4.2271-7.8599 5.5086-12.7181z"
                  stroke="#fed101"
                />
              </svg>
              <div
                className={`text-[.95rem] mt-6 font-sans font-bold uppercase`}
              >
                {quoteAuthor}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;

Clients.fragments = {
  pageEntry: gql`
    fragment ClientsPageFragment on Page_Flexiblecontent_Blocks_Clients {
      __typename
      quote
      quoteAuthor
      image {
        ...MediaItemFragment
      }
      clientsServed {
        logo {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment ClientsServiceFragment on Service_Flexiblecontent_Blocks_Clients {
      __typename
      quote
      quoteAuthor
      image {
        ...MediaItemFragment
      }
      clientsServed {
        logo {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
