import { gql } from "@apollo/client";
import {
  Page_Flexiblecontent_Blocks_EventPackages,
  Service_Flexiblecontent_Blocks_EventPackages,
} from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import Image from "next/image";
import { Content } from "components/Content";

type EventPackagesProps = (Page_Flexiblecontent_Blocks_EventPackages &
  Service_Flexiblecontent_Blocks_EventPackages) & {
  className?: string;
};

const EventPackages = ({
  className,
  leftColumnText,
  rightColumnText,
  image,
}: EventPackagesProps) => {
  const { altText, sourceUrl, mediaDetails } = image ?? {};

  return (
    <section
      className={`${className} overflow-hidden bg-black md:px-10 py-14 text-white`}
    >
      <div className="mx-auto max-w-[1500px] px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-0 lg:gap-10 lg:grid-cols-3">
          <div className={`relative flex flex-col`}>
            <div className={`mb-0 lg:mb-10 h-fit`}>
              <h2
                className={`mb-4 font-serif text-[1.8rem] font-semibold leading-none tracking-[0.04rem]`}
              >
                FULL-SERVICE <span className={`font-normal italic`}>event</span>{" "}
                SOLUTIONS
              </h2>
              <p className={"text-[.9rem] max-w-full lg:max-w-[260px]"}>
                Our packages are tailored to your unique needs, from turnkey
                production to more selective support.
              </p>
            </div>
            <div
              className={`hidden lg:block -mb-20 h-full w-full overflow-hidden rounded-t-full border-[1px] border-b-0 border-white p-10 pb-0`}
            >
              <Image
                src={sourceUrl ?? ""}
                alt={altText ?? ""}
                width={mediaDetails?.width ?? 0}
                height={mediaDetails?.height ?? 0}
                className={`h-full w-full rounded-t-full object-cover`}
              />
            </div>
          </div>
          <div className={`mt-10 lg:mt-0 ml-0 lg:ml-10`}>
            {leftColumnText && (
              <Content
                content={leftColumnText}
                className="col-span-1 text-[.9rem] leading-9"
              />
            )}
          </div>
          <div className={`lg:border-l-[1px] lg:border-white lg:pl-20`}>
            {rightColumnText && (
              <Content
                content={rightColumnText}
                className="col-span-1 text-[.9rem] leading-9"
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`block lg:hidden mt-10 -mb-20 h-[420px] w-full overflow-hidden rounded-t-full border-[1px] border-b-0 border-white p-7 pb-0`}
      >
        <div className="relative w-full h-full">
          <Image
            src={sourceUrl ?? ""}
            alt={altText ?? ""}
            fill
            className={`h-full w-full rounded-t-full object-cover`}
          />
        </div>
      </div>
    </section>
  );
};

export default EventPackages;

EventPackages.fragments = {
  pageEntry: gql`
    fragment EventPackagesPageFragment on Page_Flexiblecontent_Blocks_EventPackages {
      __typename
      leftColumnText
      rightColumnText
      image {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment EventPackagesServiceFragment on Service_Flexiblecontent_Blocks_EventPackages {
      __typename
      leftColumnText
      rightColumnText
      image {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
