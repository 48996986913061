import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_TextImage } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import Image from "next/image";
import Content from "components/Content/Content";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./TextImage.module.css";
import { useState, useEffect } from "react";

interface TextImageProps extends Page_Flexiblecontent_Blocks_TextImage {
  className?: string;
}

const TextImage = ({
  className,
  variant,
  contentType,
  title,
  text,
  sideTitle,
  contentItems,
  image,
  mobileImage,
}: TextImageProps) => {
  const { sourceUrl, altText, mediaDetails } = image ?? {};
  const { sourceUrl: mobileSourceUrl, altText: mobileAltText } =
    mobileImage ?? {};
  const [isMobile, setIsMobile] = useState(false);

  const carouselResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const hasDiamond = variant === "arched" ? true : false;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section
      className={`${className ? className : "relative overflow-hidden"}`}
    >
      <div
        className={`mx-auto max-w-[1500px] ${
          hasDiamond ? `${isMobile ? `` : `pt-14`} pb-0` : `py-14`
        }`}
      >
        <div
          className={`flex ${
            hasDiamond ? `flex-col lg:flex-row` : `flex-col md:flex-row`
          }`}
        >
          {variant === "testimonial" && (
            <div className="w-full md:w-2/5 xl:3/5 rounded-r-full border-[1px] border-l-0 border-black p-10 pl-0">
              <div className="relative h-full w-full overflow-hidden rounded-r-full min-h-[420px]">
                <Image
                  src={sourceUrl ?? ``}
                  // width={mediaDetails?.width ?? 0}
                  // height={mediaDetails?.height ?? 0}
                  alt={altText ?? ``}
                  fill
                  className={`object-cover object-center`}
                />
              </div>
            </div>
          )}
          {variant === "arched" && (
            <div className={`w-full lg:w-1/2`}>
              <div className="relative h-[300px] mb-10 lg:mb-0 lg:h-full w-full">
                {!isMobile && (
                  <Image
                    src={sourceUrl ?? ``}
                    // width={mediaDetails?.width ?? 0}
                    // height={mediaDetails?.height ?? 0}
                    alt={altText ?? ``}
                    fill
                    className={`w-full h-full object-cover object-center lg:object-contain lg:object-left-bottom`}
                  />
                )}
                {isMobile && (
                  <Image
                    src={mobileSourceUrl ?? ``}
                    alt={mobileAltText ?? ``}
                    fill
                    className={`w-full h-full object-cover object-center`}
                  />
                )}
              </div>
            </div>
          )}
          <div
            className={`relative ${
              variant === "arched"
                ? `w-full px-5 md:px-10 lg:px-0 pb-7 lg:w-1/2 lg:pr-2 lg:pb-14 lg:ml-10`
                : `w-full md:w-2/5 pl-10 pr-10 md:pl-0 md:pr-14 xl:pr-44 md:ml-24 xl:ml-40`
            }`}
          >
            {sideTitle && (
              <h3
                className={`mt-10 md:mt-0 md:absolute md:-left-14 md:top-[72px] md:-ml-20 md:-rotate-90 font-sans text-[1.25rem] tracking-widest`}
              >
                {sideTitle}
              </h3>
            )}
            <h2
              className={`${
                variant === "arched"
                  ? `max-w-[350px] mb-5 md:mb-10`
                  : `max-w-[300px] mb-5`
              }`}
            >
              <Content
                className={`font-serif ${
                  variant === "arched"
                    ? `text-[1.9rem] leading-none md:text-[2.4rem] md:leading-[44px] tracking-[0.2rem]`
                    : `text-[4rem] xl:text-[3.60rem] leading-none tracking-[0.16rem]`
                } font-semibold`}
                hasDiamond={hasDiamond ? true : false}
                content={title}
              ></Content>
            </h2>
            {contentType === "basic" && (
              <Content
                className="text-[0.95rem] font-light leading-[1.75338rem] lg:max-w-[450px]"
                content={text}
              />
            )}
            {contentType === "carousel" && (
              <div className={styles.carousel}>
                <Carousel
                  responsive={carouselResponsive}
                  showDots={(contentItems?.length || 0) <= 1 ? false : true}
                  draggable={false}
                  // swipeable={false}
                  // ssr={true}
                  renderDotsOutside={false}
                  arrows={false}
                >
                  {contentItems?.map((item, index) => {
                    return (
                      <div key={index} className={`mb-20 flex w-full flex-col`}>
                        <Content
                          className="font-sans text-[1rem] font-light tracking-[0rem] leading-[1.7rem]"
                          content={item?.text}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            )}
          </div>
        </div>
      </div>
      {variant === "testimonial" && (
        <Image
          src="/Large_Border_Graphic.svg"
          alt="Border Graphic"
          width={600}
          height={160}
          className={`absolute top-[209px] -right-[300px] w-[600px] -rotate-90`}
        />
      )}
    </section>
  );
};

export default TextImage;

TextImage.fragments = {
  pageEntry: gql`
    fragment TextImagePageFragment on Page_Flexiblecontent_Blocks_TextImage {
      __typename
      variant
      contentType
      title
      text
      sideTitle
      contentItems {
        text
      }
      image {
        ...MediaItemFragment
      }
      mobileImage {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment TextImageServiceFragment on Service_Flexiblecontent_Blocks_TextImage {
      __typename
      variant
      contentType
      title
      text
      sideTitle
      contentItems {
        text
      }
      image {
        ...MediaItemFragment
      }
      mobileImage {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
