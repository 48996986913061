import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Ticker } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Content } from "components/Content";
import Image from "next/image";
import Marquee from "react-fast-marquee";
import Balancer from "react-wrap-balancer";

interface TickerProps extends Page_Flexiblecontent_Blocks_Ticker {
  className?: string;
}

const Ticker = ({ className, title, subTitle, items }: TickerProps) => {
  return (
    <div className={`${className} bg-light-gray py-6`}>
      <div className="text-black">
        <h2 className="mb-2 text-center font-serif text-[1.4rem] md:text-[1.9rem] font-bold">
          <Content content={title} />
        </h2>
        <h3
          className={`text-[.95rem] uppercase tracking-[0.2rem] text-center mb-6 max-w-[340px] md:max-w-full mx-auto`}
        >
          {subTitle}
        </h3>
        <hr className={`border-black opacity-60`} />
        <div className="overflow-hidden">
          <Marquee className={`pb-2 pt-8`}>
            {/* Render the items twice */}
            {items?.map((item, index) => renderTickerItem(item, index))}
            {items?.map((item, index) =>
              renderTickerItem(item, index + items.length),
            )}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

// Extracted the rendering of items to its own function to avoid redundancy.
function renderTickerItem(item: any, index: number) {
  const { image, cta, title } = item ?? {};
  const { sourceUrl, altText, mediaDetails } = image ?? {};

  return (
    <div key={index} className="mx-10 flex shrink-0 flex-row items-center">
      <div className="mr-4">
        <Image
          src={sourceUrl || ``}
          alt={altText || ``}
          width={mediaDetails?.width ?? 0}
          height={mediaDetails?.height ?? 0}
          className={`mr-2 w-auto max-h-[40px] md:max-h-[60px]`}
          priority={true}
        />
      </div>
      <div className="max-w-[144px]">
        <Content
          className="font-sans text-[.8rem] md:text-[.9rem] uppercase leading-tight tracking-[0.26rem]"
          content={title}
        />
      </div>
    </div>
  );
}

export default Ticker;

Ticker.fragments = {
  pageEntry: gql`
    fragment TickerPageFragment on Page_Flexiblecontent_Blocks_Ticker {
      __typename
      title
      subTitle
      items {
        title
        cta {
          target
          title
          url
        }
        image {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment TickerServiceFragment on Service_Flexiblecontent_Blocks_Ticker {
      __typename
      title
      subTitle
      items {
        title
        cta {
          target
          title
          url
        }
        image {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
