import { gql } from "@apollo/client";
import {
  Page_Flexiblecontent_Blocks_Hero,
  Service_Flexiblecontent_Blocks_Hero,
} from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import SideImage from "./Fragments/SideImage";
import Basic from "./Fragments/Basic";

type HeroProps = (Page_Flexiblecontent_Blocks_Hero &
  Service_Flexiblecontent_Blocks_Hero) & {
  className?: string;
};

const Hero = ({
  className,
  title,
  variant,
  cta,
  image,
  subTitle,
  sideTitle,
  text,
}: HeroProps) => {
  if (variant === "side" || variant == "blog") {
    return (
      <SideImage
        variant={variant}
        className={className}
        image={image}
        title={title || ""}
        text={text || ""}
        sideTitle={sideTitle || ""}
        subTitle={subTitle || ""}
        cta={cta}
      />
    );
  } else {
    return (
      <Basic
        className={className}
        title={title || ""}
        text={text || ""}
        sideTitle={sideTitle || ""}
        subTitle={subTitle || ""}
        cta={cta}
        image={image}
      />
    );
  }
};

export default Hero;

Hero.fragments = {
  pageEntry: gql`
    fragment HeroPageFragment on Page_Flexiblecontent_Blocks_Hero {
      __typename
      sideTitle
      variant
      title
      text
      subTitle
      cta {
        target
        title
        url
      }
      image {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment HeroServiceFragment on Service_Flexiblecontent_Blocks_Hero {
      __typename
      sideTitle
      variant
      title
      text
      subTitle
      cta {
        target
        title
        url
      }
      image {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
