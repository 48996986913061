import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_CtaBlock } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Content } from "components/Content";
import { Button } from "components/Button";
import Image from "next/image";

interface CtaBlockProps extends Page_Flexiblecontent_Blocks_CtaBlock {
  className?: string;
}

const CtaBlock = ({ className, title, cta, linkIcon }: CtaBlockProps) => {
  const { sourceUrl, altText, mediaDetails } = linkIcon ?? {};
  return (
    <section
      className={`${
        className ? className : ``
      } mt-10 bg-light-gray relative overflow-hidden`}
    >
      <Image
        src="/Small_CTA_Border_Graphic-01.svg"
        alt="Border Graphic"
        width={300}
        height={150}
        className={`hidden lg:block absolute top-0 lg:-left-[250px] xl:-left-[50px] w-[300px]`}
      />
      <div className="mx-auto flex flex-col md:flex-row max-w-[1500px] items-center justify-center py-8 px-10 md:px-0">
        <h2
          className={`md:mr-10 font-serif text-[1.44rem] font-semibold tracking-[0.125rem] text-center md:text-left`}
        >
          <Content content={title} />
        </h2>
        {cta ? (
          <Button
            type={`primary`}
            className={`mt-4 md:mt-0 flex w-fit flex-row items-center lg:border-[1px] lg:border-black text-[1rem] lg:py-2 lg:px-6 lg:hover:border-primary lg:hover:bg-primary lg:hover:text-black`}
            href={cta.url ?? ``}
          >
            {linkIcon ? (
              <Image
                src={sourceUrl || ``}
                alt={altText || ``}
                width={mediaDetails?.width ?? 0}
                height={mediaDetails?.height ?? 0}
                className={`mr-4 w-3`}
              />
            ) : null}
            {cta.title}
          </Button>
        ) : null}
      </div>
      <Image
        src="/Small_CTA_Border_Graphic-01.svg"
        alt="Border Graphic"
        width={300}
        height={150}
        className={`hidden lg:block absolute top-0 lg:-right-[250px] xl:-right-[50px] w-[300px] -scale-x-100`}
      />
    </section>
  );
};

export default CtaBlock;

CtaBlock.fragments = {
  pageEntry: gql`
    fragment CtaBlockPageFragment on Page_Flexiblecontent_Blocks_CtaBlock {
      __typename
      title
      cta {
        target
        title
        url
      }
      linkIcon {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment CtaBlockServiceFragment on Service_Flexiblecontent_Blocks_CtaBlock {
      __typename
      title
      cta {
        target
        title
        url
      }
      linkIcon {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
