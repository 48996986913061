import Image from 'next/image'
import React from 'react'

interface PostGridProps {
  posts: any;
}

const PostGrid = ({posts} : PostGridProps) => {
  return (
    <div className="mt-[72px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-0 px-7 md:px-10 lg:px-16 max-w-[1500px] mx-auto w-full">
      {posts &&
        posts?.nodes.map((post: any, index: number) => {
          const prettyDate = new Date(post.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          return (
            <React.Fragment key={index}>
              <div className="w-full flex flex-col items-start">
                {post?.featuredImage?.node && (
                  <div className="relative w-[13.2rem] h-[13.2rem] overflow-hidden rounded-full">
                    <Image
                      className="h-full w-full object-cover"
                      fill
                      src={post.featuredImage.node.sourceUrl}
                      alt={post.featuredImage.node.altText}
                    />
                  </div>
                )}
                <div className="py-4">
                  <p className="text-[1.1rem] mt-4 mb-4">{prettyDate}</p>
                  <div className="mb-0 text-[1.3rem] font-semibold md:max-w-[250px] lg:max-w-[186px]">
                    {post.title}
                  </div>
                </div>
                <div className="w-full pb-2">
                  <a
                    href={`/` + post.slug}
                    className="group text-md mb-2 mr-2 flex flex-row py-1 font-sans font-bold text-[0.95rem]"
                  >
                    Read More{" "}
                    <Image
                      src="/right-arrow.svg"
                      width={22}
                      height={22}
                      alt="Read More"
                      className="ml-4 mt-0 group-hover:ml-6 transition-all duration-300 ease-in-out"
                    />
                  </a>
                </div>
              </div>
              {index % 3 === 2 && <hr className="w-full md:col-span-3 my-4" />}
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default PostGrid