import { gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout } from "features";
import { NavigationMenu } from "components";
import { PostSingle } from "components/PostSingle";
import { ContactCta } from "components/ContactCta";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
  MEDIA_ITEM_FRAGMENT,
} from "fragments";

export default function Component(props) {
  const { data, loading, error } = props;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { post, headerMenuItems, footerMenuItems, siteSettings } = data;
  const { seo, title, content, date, featuredImage } = post;
  const {
    address,
    customAddressLabel,
    phoneNumber,
    logo,
    logoWhite,
    logoAlt,
    cta,
    ctaIcon,
    email,
  } = siteSettings.siteSettings;

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo}
      logoWhite={logoWhite}
      logoAlt={logoAlt}
      cta={cta}
      ctaIcon={ctaIcon}
    >
      <PostSingle
        title={title}
        content={content}
        date={date}
        featuredImage={featuredImage}
      />
      <ContactCta />
    </Layout>
  );
}

Component.query = gql`
  query GetServiceData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
    $asPreview: Boolean = false
  ) {
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      title
      date
      content
      featuredImage {
        node {
          ...MediaItemFragment
        }
      }
      seo {
        ...SEOFragment
      }
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
  ${MEDIA_ITEM_FRAGMENT}
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
