import { gql } from "@apollo/client";
import Image from "next/image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./Services.module.css";
import { ButtonGroupProps, ArrowProps } from "react-multi-carousel/lib/types";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Page_Flexiblecontent_Blocks_Services } from "graphql";

interface CustomLeftArrowProps extends ArrowProps {
  className?: string;
}
interface CustomRightArrowProps extends ArrowProps {
  className?: string;
}
interface CarouselButtonGroupProps extends ButtonGroupProps {
  className?: string;
  next?: () => void;
  previous?: () => void;
}

interface servicesProps extends Page_Flexiblecontent_Blocks_Services {
  className?: string;
}

const Services = ({ servicesOverride }: servicesProps) => {
  const carouselResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const CustomLeftArrow = ({ className, onClick }: CustomLeftArrowProps) => {
    return (
      <button className={className} onClick={onClick}>
        <Image src="/right-arrow.svg" width={22} height={22} alt="Read More" />
      </button>
    );
  };
  const CustomRightArrow = ({ className, onClick }: CustomRightArrowProps) => {
    return (
      <button className={className} onClick={onClick}>
        <Image src="/right-arrow.svg" width={22} height={22} alt="Read More" />
      </button>
    );
  };

  const ButtonGroup = ({
    next,
    previous,
    carouselState,
  }: CarouselButtonGroupProps) => {
    const { currentSlide } = carouselState ?? {};
    const lastPage = Services.length - 5;
    return (
      <div className="carousel-button-group">
        <CustomLeftArrow
          className={`absolute left-0 top-1/2 -translate-y-1/2 ml-5 lg:ml-10 transition-all duration-300 ease-in-out rotate-180`}
          onClick={() => previous?.()}
        />
        <CustomRightArrow
          className={`absolute right-0 top-1/2 -translate-y-1/2 mr-5 lg:mr-10 transition-all duration-300 ease-in-out`}
          onClick={() => next?.()}
        />
      </div>
    );
  };

  return (
    <section>
      <div className="relative mx-auto mb-10 max-w-[1500px] px-5 lg:px-20 pb-10 md:pb-20">
        <div className="relative flex items-center">
          <h2
            className={`my-10 flex flex-row text-left font-serif font-bold tracking-wider`}
          >
            <span className="font-serif text-[1.35rem] font-semibold">
              EVENTS
            </span>{" "}
            <span
              className={`ml-2 font-serif text-[1.35rem] font-normal italic`}
            >
              elevated
            </span>
          </h2>
          <div className="ml-10 h-[1px] w-full bg-black"></div>
        </div>
        <div className={styles.carousel}>
          <Carousel
            responsive={carouselResponsive}
            showDots={true}
            draggable={false}
            // swipeable={false}
            renderDotsOutside={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
          >
            {servicesOverride?.map((service, index) => {
              const image = service?.categoryOptions?.categoryImage;
              const { sourceUrl, altText, mediaDetails } = image ?? {};
              return (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-center`}
                >
                  <div
                    className={`relative overflow-hidden rounded-full w-[100px] h-[100px] md:min-w-[200px] md:min-h-[200px]`}
                  >
                    <Image
                      src={sourceUrl ?? ""}
                      alt={altText ?? ""}
                      fill
                      className={`h-full w-full object-cover`}
                    />
                  </div>
                  <h3
                    className={`text-[.75rem] md:text-[.9rem] mx-auto mt-5 max-w-[12rem] text-center font-bold uppercase`}
                  >
                    {service?.name}
                  </h3>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Services;

Services.fragments = {
  pageEntry: gql`
    fragment ServicesPageFragment on Page_Flexiblecontent_Blocks_Services {
      __typename
      servicesOverride {
        uri
        description
        categoryOptions {
          itemOrder
          categoryImage {
            ...MediaItemFragment
          }
        }
        slug
        name
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment ServicesServiceFragment on Service_Flexiblecontent_Blocks_Services {
      __typename
      servicesOverride {
        uri
        description
        categoryOptions {
          itemOrder
          categoryImage {
            ...MediaItemFragment
          }
        }
        slug
        name
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
