import React from "react";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { Content } from "components/Content";
import Image from "next/image";
import { Button } from "components/Button";

interface SideImageProps {
  variant?: string;
  image?: any;
  className?: string;
  title?: string;
  text?: string;
  sideTitle?: string;
  subTitle?: string;
  cta?: any;
}

const SideImage = ({
  variant,
  image,
  className,
  title,
  text,
  sideTitle,
  subTitle,
  cta,
}: SideImageProps) => {
  const { sourceUrl, altText, mediaDetails } = image ?? {};

  return (
    <section className={`${className ? className : ""} mb-[1.5rem]`}>
      <div className="mx-auto max-w-[1500px] pt-8 pb-0 md:py-8">
        <div className="flex md:flex-row flex-col">
          <div className="relative px-7 md:px-0 md:ml-20 lg:ml-40 w-full md:w-2/5 pt-5 md:pt-16">
            <h3
              className={`md:absolute  ${
                title !== "Ninth Floor" && variant !== "blog"
                  ? `md:top-[8rem] lg:top-[8.4rem] md:-left-5 lg:-left-[3.75rem]`
                  : `md:top-24 md:left-0 lg:-left-7`
              } md:-ml-20 md:-rotate-90 font-sans text-lg tracking-widest`}
            >
              {sideTitle}
            </h3>
            <h1 className="mb-8 max-w-md">
              {variant === "blog" ? (
                <>
                  <div className="font-serif italic text-xl">
                    the{" "}
                    <span className="font-semibold uppercase not-italic">
                      latest
                    </span>{" "}
                    on the
                  </div>
                  <div className="font-serif text-[2.5rem] lg:text-[4rem] xl:text-[4.1rem] font-semibold uppercase tracking-[0.28125rem] leading-none">
                    NINTH FLOOR
                  </div>
                </>
              ) : (
                <>
                  <Content
                    className="font-serif text-[2.5rem] lg:text-[4rem] xl:text-[4.1rem] font-semibold uppercase tracking-[0.28125rem] leading-none"
                    content={title}
                  />
                  {subTitle && (
                    <Content
                      className="font-serif text-[2.5rem] lg:text-[4rem] xl:text-[4.1rem] italic tracking-[0.28125rem] leading-[44px] mb-8"
                      content={subTitle}
                    />
                  )}
                </>
              )}
            </h1>
            {cta && (
              <Button
                type="primary"
                className="mb-5 lg:border-[1px] lg:py-2 lg:px-14 lg:border-black"
                href={cta?.link}
              >
                {cta?.title}
              </Button>
            )}
          </div>
          <div
            className={`ml-8 ${
              title === "Ninth Floor" ? `mt-10` : ``
            } md:mt-0 w-auto md:w-3/5 rounded-l-full border-[1px] border-r-0 border-black p-7 pr-0`}
          >
            <div className="relative overflow-hidden rounded-l-full h-full min-h-[200px] md:min-h-[330px]">
              <Image
                src={sourceUrl ?? ``}
                // width={mediaDetails?.width ?? 0}
                // height={mediaDetails?.height ?? 0}
                alt={altText ?? ``}
                fill
                className={`object-cover object-center w-full h-full`}
              />
            </div>
          </div>
        </div>
        {variant === "blog" && text && (
          <div className="mt-8 md:mt-16 text-[1rem] md:text-[1.171875rem] leading-[1.7rem] mx-auto max-w-[1500px] px-10 lg:px-16">
            <Content content={text} />
          </div>
        )}
      </div>
    </section>
  );
};

export default SideImage;
