import React, { useEffect } from "react";
import clsx from "clsx";
import Image from "next/image";
import { Button } from "components/Button";
import { motion, useAnimation } from "framer-motion";

interface BaseProps {
  image?: any;
  className?: string;
  title?: string;
  subTitle?: string;
  sideTitle?: string;
  text?: string;
  cta?: any;
}

const Basic = ({
  image,
  className,
  title,
  text,
  subTitle,
  sideTitle,
  cta,
}: BaseProps) => {
  const { sourceUrl = "", altText, mediaDetails = {} } = image || {};
  const imageProps = {
    src: sourceUrl || "",
    alt: altText || title || "",
    width: mediaDetails?.width || 0,
    height: mediaDetails?.height || 0,
  };

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      y: ["0%", "20%", "0%"], // Moves the arrow down by 10% of its height then back up to create a bounce effect.
      x: ["-50%"], // Centers the arrow horizontally.
      transition: { duration: 0.8, repeat: Infinity }, // Repeats the animation indefinitely.
    });
  }, [controls]);

  return (
    <section
      className={clsx("relative -mt-[108px] h-screen w-full", className)}
    >
      <div
        className={`absolute z-10 flex h-full w-full flex-col items-center justify-center bg-[rgba(30,30,30,0.45)] text-white`}
      >
        <h1 className="font-serif text-[4.1rem] font-semibold uppercase tracking-[0.125rem] leading-[56px] md:leading-[40px] text-center">
          {title}
        </h1>
        <h2
          className={`font-serif text-[4.1rem] italic tracking-[0.125rem] leading-none`}
        >
          {subTitle}
        </h2>
        <p className="mt-3 font-sans text-[1.19rem] font-bold">{text}</p>
        <div className="mt-10 flex flex-row items-center justify-center">
          <Button
            type="primary"
            className="border-[1px] lg:border-black hover:lg:border-black text-[1rem] xl:px-14 xl:py-2"
            href={cta?.url}
            // onClick={(e) => {
            //   e.preventDefault();
            //   const target = "#about";
            //   const targetElement = document.querySelector(target);
            //   if (targetElement) {
            //     const position =
            //       targetElement.getBoundingClientRect().top + window.pageYOffset;

            //     window.scrollTo({
            //       top: position - 80, // Subtracting 80px offset
            //       behavior: "smooth",
            //     });
            //   }
            // }}
          >
            {cta?.title}
          </Button>
          <a
            href="https://youtu.be/peFqciK-4TM"
            target="_blank"
            rel="noreferrer"
            className="group flex flex-row ml-4 bg-black border-[1px] lg:border-white hover:bg-primary hover:border-black hover:text-black text-[1rem] px-6 py-2 rounded-full transition-colors duration-200 ease-in-out"
          >
            <svg
              className="mr-2"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 32">
                <g id="Group">
                  <path
                    className="fill-white group-hover:fill-black transition duration-300 ease-in-out"
                    d="M20.7778 5.96444C19.9956 4.47333 18.8956 3.15333 17.5267 2.15111C17.3311 2.00444 17.0378 2.05333 16.8911 2.24889C16.7444 2.44444 16.7933 2.73778 16.9889 2.88444C18.2356 3.81333 19.2622 5.01111 19.9711 6.38C20.7044 7.82222 21.0956 9.36222 21.0956 11C21.0956 16.5733 16.5733 21.0956 11 21.0956C5.42667 21.0956 0.904444 16.5733 0.904444 11C0.904444 5.42667 5.42667 0.904444 11 0.904444C11.2444 0.904444 11.44 0.708889 11.44 0.464444C11.44 0.22 11.2444 0 11 0C4.93778 0 0 4.93778 0 11C0 17.0622 4.93778 22 11 22C17.0622 22 22 17.0622 22 11C22 9.24 21.5844 7.50444 20.7778 5.96444Z"
                  />
                </g>
                <g id="Group_2">
                  <path
                    className="fill-white group-hover:fill-black transition duration-300 ease-in-out"
                    d="M8.18899 5.89106C8.04233 5.96439 7.96899 6.11106 7.96899 6.28217V16.1088C7.96899 16.3533 8.16455 16.5488 8.40899 16.5488C8.65344 16.5488 8.84899 16.3533 8.84899 16.1088V7.06439L15.4734 10.9999L10.609 14.1533C10.389 14.2755 10.3401 14.5688 10.4868 14.7644C10.609 14.9844 10.9023 15.0333 11.0979 14.8866L16.5734 11.3666C16.6957 11.2933 16.7934 11.1466 16.769 10.9755C16.769 10.8288 16.6957 10.6822 16.549 10.6088L8.62899 5.89106C8.50677 5.81773 8.33566 5.81773 8.18899 5.89106Z"
                  />
                </g>
              </g>
            </svg>
            <span>Our Expertise</span>
          </a>
        </div>
      </div>
      <Image
        src={sourceUrl}
        alt={imageProps.alt}
        fill
        className={`h-full w-full object-cover`}
      />
      <motion.div
        className={`absolute bottom-10 left-1/2 z-10`}
        animate={controls}
      >
        <DownArrow />
      </motion.div>
    </section>
  );
};

const DownArrow = () => {
  return (
    <svg width="32" height="24" fill="none" viewBox="0 0 32 24">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 1l15.556 21L31 1"
      ></path>
    </svg>
  );
};

export default Basic;
