import React from "react";
import Image from "next/image";
import Link from "next/link";
import ContactForm from "./Fragments/ContactForm";

const ContactPage = () => {
  return (
    <section className={`mt-10 md:mt-20`}>
      <div className={`mx-auto max-w-[1500px] px-10`}>
        <div className="flex flex-col md:flex-row max-w-[1200px] mx-auto">
          <div className="w-full md:w-3/5 md:pr-10 lg:pl-8 xl:pl-0 lg:pr-20 md:border-r-[1px] md:border-black">
            <div className={`relative`}>
              <h3
                className={`lg:absolute lg:-left-0 lg:top-12 lg:-ml-20 lg:-rotate-90 font-sans text-xl tracking-widest font-bold`}
              >
                CONTACT
              </h3>
              <h2
                className={`font-serif text-6xl font-semibold max-w-[490px] mb-10 tracking-widest`}
              >
                NINTH FLOOR <span className={`font-normal italic`}>events</span>
              </h2>
              <h3 className={`font-serif italic font-semibold text-3xl mb-4`}>
                We want to elevate your next event.
              </h3>
            </div>
            <p className={``}>
              We are available for contact Monday - Friday during normal
              business hours. Please fill out our contact form below or contact
              us at any of the channels below.
            </p>
          </div>
          <div className="mt-10 md:mt-0 w-full md:w-2/5 md:pl-10 lg:pl-20">
            <Image
              src="https://cms.ninthfloorevents.com/wp-content/uploads/2023/09/5fc7d8bbcd7bb40191afaa0b682db824.png"
              width={175}
              height={175}
              alt="Ninth Floor Events"
              className={`rounded-full object-cover mb-6 mx-auto md:mx-0`}
            />
            <h3
              className={`font-serif text-2xl lg:text-4xl font-semibold italic mb-2 text-center md:text-left`}
            >
              FRAN YANITY
            </h3>
            <p
              className={`italic text-md lg:text-xl font-serif text-center md:text-left`}
            >
              210.298.2677
            </p>
            <p
              className={`italic text-md lg:text-xl font-serif text-center md:text-left`}
            >
              fran.yanity@ninthfloorevents.com
            </p>
          </div>
        </div>
        <div
          className={`flex flex-col md:flex-row max-w-[1300px] mx-auto mt-10 md:mt-20`}
        >
          {/* <div className={`order-3 md:order-none mt-10 md:mt-0 w-full md:w-1/2 lg:w-3/5 mr-10 lg:mr-20`}>
            <Link
              href="https://www.google.com/maps/place/7550+I-10,+San+Antonio,+TX+78229/@29.504157,-98.5540374,17z/"
              target="_blank"
            >
              <Image
                src="/map-img.png"
                width={1200}
                height={1200}
                alt="Ninth Floor Events"
                className={`rounded-3xl object-cover mb-6`}
              />
            </Link>
          </div> */}
          {/* OG Width: md:w-1/2 lg:w-2/5 */}
          <div
            className={`w-full mt-7 md:mt-0 flex flex-col justify-start lg:ml-10`}
          >
            {/* <h2
              className={`mb-2 font-serif text-5xl font-semibold tracking-wide text-black`}
            >
              <span className={`font-normal italic`}>Let’s</span>
              <br />
              START THE CONVERSATION
            </h2> */}
            {/* <p className={`mb-6`}>
              Want to find out more about how we can help you or have a general
              question? Fill out the form below, and someone from our team will get
              back to you within 48 hours!
            </p> */}
            <div className={`flex flex-col`}>
              {/* <h3 className={`font-serif font-semibold text-lg mb-3`}>
                CONTACT <span className={`font-normal italic`}>form</span>
              </h3> */}
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
