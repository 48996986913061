import { gql } from "@apollo/client";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Content } from "components/Content";
import { Button } from "components/Button";
import Image from "next/image";
import { Page_Flexiblecontent_Blocks_About } from "graphql";
import styles from "./About.module.css";

interface AboutProps extends Page_Flexiblecontent_Blocks_About {
  className?: string;
}

const About = ({
  className,
  title,
  text,
  subTitle,
  ourWork,
  cta,
}: AboutProps) => {
  return (
    <section id="about" className={`${className} bg-black overflow-hidden`}>
      <div className="mx-auto flex max-w-[1500px] flex-col lg:flex-row py-20 text-white">
        <div
          className={`relative px-10 lg:px-0 lg:ml-20 lg:mr-28 w-full lg:w-3/5 xl:w-2/5 text-left`}
        >
          <h3
            className={`lg:absolute lg:right-full lg:top-[29px] lg:-rotate-90 font-sans text-[1.25rem] tracking-widest`}
          >
            ABOUT
          </h3>
          <h2 className="mb-8">
            <Content
              className="font-serif text-[4rem] xl:text-[4rem] font-semibold tracking-[0.2rem] leading-none max-w-[500px] lg:max-w-full"
              content={title}
            ></Content>
          </h2>
          <hr className="mb-6" />
          <h3 className="font-serif text-[2.2rem] xl:text-[2.13rem] font-semibold italic">
            {subTitle}
          </h3>

          <Content
            className="my-6 mb-14 font-sans text-[.9rem] tracking-[0.02rem]"
            content={text}
          />

          {cta && (
            <Button
              type="primary"
              href={cta?.url ?? ""}
              className="mx-auto lg:px-10 lg:py-2"
            >
              {cta?.title}
            </Button>
          )}
        </div>
        <div
          className={`flex w-full lg:w-2/5 xl:w-3/5 flex-col items-end mt-10 lg:mt-0`}
        >
          <div
            className={`${styles.scrollBars} flex flex-row h-[400px] lg:h-full lg:min-h-[400px] w-full overflow-x-scroll md:overflow-hidden gap-5 pb-6 md:-mr-8`}
          >
            {ourWork?.map((item, index) => {
              const { image, cta } = item ?? {};
              const { sourceUrl, altText, mediaDetails } = image ?? {};
              return (
                <div
                  key={index}
                  className={`relative h-full w-full overflow-hidden rounded-t-full min-w-[250px] ${
                    index !== 0 ? `grayscale` : ``
                  }`}
                >
                  <Image
                    src={sourceUrl || ``}
                    alt={altText || ``}
                    fill
                    className={`h-full w-full object-cover`}
                  />
                </div>
              );
            })}
          </div>
          {/* <Button
            className={`lg:mr-20 lg:px-14 lg:py-2`}
            type="primary"
            href="/our-work"
          >
            View Our Work
          </Button> */}
        </div>
      </div>
    </section>
  );
};

export default About;

About.fragments = {
  pageEntry: gql`
    fragment AboutPageFragment on Page_Flexiblecontent_Blocks_About {
      __typename
      subTitle
      text
      title
      ourWork {
        cta {
          url
          title
          target
        }
        image {
          ...MediaItemFragment
        }
      }
      cta {
        url
        title
        target
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment AboutServiceFragment on Service_Flexiblecontent_Blocks_About {
      __typename
      subTitle
      text
      title
      ourWork {
        cta {
          url
          title
          target
        }
        image {
          ...MediaItemFragment
        }
      }
      cta {
        url
        title
        target
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
