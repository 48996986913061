import { Content } from "components/Content";
import { MediaItem } from "graphql";
import Image from "next/image";
import Link from "next/link";
import React from "react";

interface PostSingleProps {
  title: string;
  content: string;
  date: string;
  featuredImage?: any;
}

const PostSingle = ({
  title,
  date,
  content,
  featuredImage,
}: PostSingleProps) => {
  const prettyDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <section className="py-8 px-5 max-w-[1100px] mx-auto">
      <div>
        <Link
          href="/blog"
          className="text-black flex flex-row items-center mb-6"
        >
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <span className="ml-2">Back to Blog</span>
        </Link>
        <div className={`flex items-center justify-between`}>
          <h1 className="w-full lg:w-[60%] mb-4 font-serif text-4xl md:text-6xl font-semibold uppercase pr-5 relative">
            <div
              className={`absolute top-6 mr-5 right-full -rotate-90 font-sans text-lg tracking-widest`}
            >
              BLOG
            </div>
            {title}
          </h1>
          <div className="flex-1 h-[1px] bg-black opacity-50" />
        </div>
        <span className="block mb-6">{prettyDate}</span>
      </div>
      <div className="pt-10">
        {featuredImage?.node && (
          <div className="relative w-full lg:w-[21rem] h-[12rem] md:h-[23rem] lg:h-[28rem] overflow-hidden lg:rounded-t-full lg:float-left mr-10 mb-10">
            <Image
              className="h-full w-full object-cover"
              fill
              src={featuredImage?.node.sourceUrl ?? ""}
              alt={featuredImage?.node.altText ?? ""}
            />
          </div>
        )}
        <Content className="font-sans not-italic" content={content} />
      </div>
    </section>
  );
};

export default PostSingle;
