import { gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout } from "features";
import { NavigationMenu } from "components";
import Image from "next/image";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
} from "fragments";

export default function Component(props) {
  const { data, loading, error } = props;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { page, headerMenuItems, footerMenuItems, siteSettings } = data;
  const { seo } = page;
  const { logo, logoWhite, logoAlt, cta, ctaIcon } = siteSettings.siteSettings;

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo}
      logoWhite={logoWhite}
      logoAlt={logoAlt}
      cta={cta}
      ctaIcon={ctaIcon}
    >
      <section className={`mt-10 md:mt-20`}>
        <div className={`mx-auto max-w-[1500px] px-10`}>
          <div className="flex flex-col md:flex-row max-w-[1200px] mx-auto">
            <div className="w-full md:w-3/5 md:pr-10 lg:pr-20 md:border-r-[1px] md:border-black">
              <div className={`relative`}>
                <h3
                  className={`lg:absolute lg:-left-0 lg:top-12 lg:-ml-20 lg:-rotate-90 font-sans text-xl tracking-widest font-bold`}
                >
                  CONTACT
                </h3>
                <h2
                  className={`font-serif text-6xl font-semibold max-w-[490px] mb-10 tracking-widest`}
                >
                  NINTH FLOOR{" "}
                  <span className={`font-normal italic`}>events</span>
                </h2>
                <h3 className={`font-serif italic font-semibold text-3xl mb-4`}>
                  We want to elevate your next event.
                </h3>
              </div>
              <p className={``}>
                We are available for contact Monday - Friday during normal
                business hours. Please fill out our contact form below or
                contact us at any of the channels below.
              </p>
            </div>
            <div className="mt-10 md:mt-0 w-full md:w-2/5 md:pl-10 lg:pl-20">
              <Image
                src="https://cms.ninthfloorevents.com/wp-content/uploads/2023/09/5fc7d8bbcd7bb40191afaa0b682db824.png"
                width={175}
                height={175}
                alt="Ninth Floor Events"
                className={`rounded-full object-cover mb-6 mx-auto md:mx-0`}
              />
              <h3
                className={`font-serif text-2xl lg:text-4xl font-semibold italic mb-2 text-center md:text-left`}
              >
                FRAN YANITY
              </h3>
              <p
                className={`italic text-md lg:text-xl font-serif text-center md:text-left`}
              >
                210.298.2677
              </p>
              <p
                className={`italic text-md lg:text-xl font-serif text-center md:text-left`}
              >
                fran.yanity@ninthfloorevents.com
              </p>
            </div>
          </div>
          <div
            className={`flex flex-col md:flex-row mx-auto mt-10 md:mt-20 justify-start md:justify-center`}
            style={{ maxWidth: "800px" }}
          >
            <div
              className={`mt-10 md:mt-0 w-full md:w-1/2 lg:w-fit mr-10 lg:mr-10`}
            >
              <h2
                className={`mb-10 font-serif text-6xl font-semibold tracking-wide text-black`}
              >
                <span className={`font-normal italic`}>Thank</span>
                <br />
                YOU
              </h2>
            </div>
            <div
              className={`w-full mt-7 md:mt-0 md:w-1/2 lg:w-2/3 flex flex-col justify-start lg:ml-10`}
            >
              <p className={`mb-10`}>
                For considering Ninth Floor Events. We look forward to the
                possibility of working with you.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

Component.query = gql`
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
    $asPreview: Boolean = false
  ) {
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }

    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      seo {
        ...SEOFragment
      }
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
