import frontPage from "./front-page";
import page from "./page";
import archivePost from "./archive-post";
import pageContact from "./page-contact-us";
import pageThankYou from "./page-thank-you";
import singleService from "./single-service";
import singlePost from "./single-post";
import index from "./page-index";

export default {
  "front-page": frontPage,
  page,
  "archive-post": archivePost,
  "page-contact-us": pageContact,
  "page-thank-you": pageThankYou,
  "single-service": singleService,
  "single-post": singlePost,
  index
};
