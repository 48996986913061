import { gql } from "@apollo/client";
import { MenuItem } from "graphql";
import Link from "next/link";
import { AnimatePresence, m, LazyMotion } from "framer-motion";
import { itemVariants, sideVariants } from "./Fragments/variants";
import { MenuButton } from "./Fragments/MenuButton";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import Image from "next/image";
import { useState } from "react";

const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

interface NavigationMenuProps {
  menuItems: MenuItem[] | undefined;
  className?: string;
  type?: "primary" | "secondary";
  isMobile?: boolean;
  isOpen?: boolean;
  toggleOpen?: () => void;
}

const NavigationMenu = ({
  menuItems,
  className,
  type = "primary",
  isOpen,
  toggleOpen,
}: NavigationMenuProps) => {
  const [showChild, setShowChild] = useState<string | null>(null);
  if (!menuItems) {
    return null;
  }
  return (
    <>
      <LazyMotion features={loadFeatures}>
        <AnimatePresence>
          <MenuButton
            className={`absolute top-10 left-5 ${
              isOpen ? `top-10` : `top-50`
            } z-50 lg:hidden`}
            isOpen={isOpen}
            onClick={toggleOpen}
            lineProps={{ strokeLinecap: "round" }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            width="24"
            height="18"
            strokeWidth="3"
            color="#FFF"
          />
          {isOpen && (
            <m.aside
              initial={{ width: `100%` }}
              className={`z-50 grid h-screen items-center`}
            >
              <m.nav
                className={`bottom-100 font-heading left-4 z-40 flex flex-col items-center gap-8 text-base lg:relative lg:flex-row lg:gap-0`}
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                {menuItems.map(({ path, label, childItems }) => {
                  const hasChildren = childItems?.nodes?.length ?? 0;
                  return (
                    <m.div
                      key={path}
                      variants={itemVariants}
                      className={`w-full`}
                    >
                      <Link
                        href={path ?? "/"}
                        className={`mr-5 ${
                          showChild === path ? `text-primary` : `text-white`
                        } uppercase font-normal tracking-[0.26rem] text-center flex items-center justify-center`}
                        onClick={(e) => {
                          if (hasChildren > 0) {
                            e.preventDefault();
                            setShowChild(
                              showChild === path ? null : path ?? "",
                            );
                          }
                        }}
                      >
                        {label}
                        {hasChildren > 0 && (
                          <span>
                            <Image
                              src="/nav-arrow.png"
                              alt="Arrow"
                              width={8}
                              height={7}
                              className={`ml-2 -rotate-90 mt-1`}
                            />
                          </span>
                        )}
                      </Link>
                      {hasChildren > 0 &&
                        childItems?.nodes &&
                        showChild === path && (
                          <div className={`bg-[#343434] py-1 mt-4 w-full`}>
                            <ul
                              className={`flex flex-col items-center justify-center w-full`}
                            >
                              {childItems.nodes.map(
                                ({ path, label, menuOptions }: any) => (
                                  <li
                                    key={path}
                                    className={`flex flex-col py-1 px-5 items-center not-last:border-b-[1px] border-[#4F4F4F] w-full`}
                                  >
                                    <Link
                                      href={path ?? "/"}
                                      className={`uppercase tracking-widest text-[.9rem] text-white text-center font-bold`}
                                    >
                                      <span
                                        className={`pb-1 border-b-2 border-transparent transition-all duration-300`}
                                      >
                                        {label}
                                      </span>
                                    </Link>
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>
                        )}
                    </m.div>
                  );
                })}
                <m.div variants={itemVariants} className={`w-full lg:hidden`}>
                  <Link
                    href={"/contact-us"}
                    className={`flex mr-5 text-white uppercase font-normal tracking-[0.26rem] text-center items-center justify-center`}
                  >
                    Contact Us
                  </Link>
                </m.div>
              </m.nav>
            </m.aside>
          )}
        </AnimatePresence>
      </LazyMotion>
      <nav className="font-heading left-4 hidden flex-col items-center text-sm lg:flex lg:flex-row self-stretch">
        <ul
          className={`flex-col items-center text-sm lg:flex lg:flex-row self-stretch`}
        >
          {menuItems.map(({ path, label, childItems }) => {
            const hasChildren = childItems?.nodes?.length ?? 0;
            return (
              <li
                key={path}
                className={`self-stretch flex justify-center items-center group`}
              >
                <Link
                  href={path ?? "/"}
                  className={`flex flex-wrap items-center px-8 uppercase text-md xl:text-[0.9rem] font-bold tracking-[0.26rem] text-white ${
                    hasChildren
                      ? `group-hover:text-primary`
                      : `hover:text-primary`
                  } block`}
                >
                  {label}
                  {hasChildren > 0 && (
                    <span>
                      <Image
                        src="/nav-arrow.png"
                        alt="Arrow"
                        width={8}
                        height={7}
                        className={`ml-2`}
                      />
                    </span>
                  )}
                </Link>
                {hasChildren > 0 && childItems?.nodes && (
                  <div
                    className={`absolute top-full left-0 w-full bg-light-gray py-8 px-10 hidden group-hover:block overflow-hidden`}
                  >
                    <Image
                      src="/Large_Border_Graphic.svg"
                      alt="Border Graphic"
                      width={1600}
                      height={600}
                      className={`absolute top-[160px] -left-[300px] w-[600px] -rotate-90 -scale-y-100`}
                    />
                    <Image
                      src="/Large_Border_Graphic.svg"
                      alt="Border Graphic"
                      width={1600}
                      height={600}
                      className={`absolute top-[160px] -right-[300px] w-[600px] -rotate-90`}
                    />
                    <ul className={`flex flex-row justify-center`}>
                      {childItems.nodes.map(
                        ({ path, label, menuOptions }: any) => (
                          <li
                            key={path}
                            className={`flex flex-col mx-6 xl:mx-10 items-center`}
                          >
                            <Link
                              href={path ?? "/"}
                              className={`uppercase tracking-widest text-[.9rem] text-black group/child block text-center font-bold`}
                            >
                              <Image
                                src={
                                  menuOptions?.menuItemImage?.sourceUrl ?? ``
                                }
                                alt={menuOptions?.menuItemImage?.altText ?? ``}
                                width={
                                  menuOptions?.menuItemImage?.mediaDetails
                                    ?.width ?? 0
                                }
                                height={
                                  menuOptions?.menuItemImage?.mediaDetails
                                    ?.height ?? 0
                                }
                                className={`lg:w-[125px] xl:w-[181px] lg:h-[125px] xl:h-[181px] mb-4 object-cover rounded-full mx-auto`}
                              />
                              <span
                                className={`pb-1 border-b-2 border-transparent group-hover/child:border-black transition-all duration-300`}
                              >
                                {label}
                              </span>
                            </Link>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default NavigationMenu;

NavigationMenu.fragments = {
  entry: gql`
    fragment NavigationMenuItemFragment on MenuItem {
      id
      path
      label
      parentId
      cssClasses
      childItems {
        nodes {
          id
          path
          label
          parentId
          cssClasses
          menuOptions {
            menuItemImage {
              ...MediaItemFragment
            }
          }
        }
      }
      menu {
        node {
          name
        }
      }
      menuOptions {
        menuItemImage {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
