import { gql } from "@apollo/client";
import { MEDIA_ITEM_FRAGMENT } from "./MediaItemFragment";

export const NAVIGATION_MENU_ITEM_FRAGMENT = gql`
  fragment NavigationMenuItemFragmentParent on MenuItem {
    id
    label
    url
    uri
    childItems {
      nodes {
        id
        path
        label
        url
        uri
        menuOptions {
          menuItemImage {
            ...MediaItemFragment
          }
        }
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
