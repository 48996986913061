// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
function ContactForm() {
  const [state, handleSubmit] = useForm("xyyqbgae");
  const [showReferralOrOther, setShowReferralOrOther] = useState(false);

  const inputClass = `w-full border-[1px] border-black py-2 px-4 mb-2 placeholder:text-gray-400 font-serif italic text-lg outline-none focus:border-primary hover:border-primary transition-all focus:placeholder:text-gray-300`;
  const textAreaClass = `border-[1px] border-black py-2 px-4 mb-2 placeholder:text-gray-400 font-serif italic text-lg outline-none focus:border-primary hover:border-primary transition-all focus:placeholder:text-gray-300`;
  const labelClass = `font-semibold text-black font-serif italic text-xl mb-2 block`;
  const checkBoxClass = `mr-3`;
  const checkBoxLabelClass = `text-black font-serif italic text-lg mr-4`;
  const radioLabelClass = `block text-black font-serif italic text-lg mb-1`;

  const handleRadioChange = (event: any) => {
    if (event.target.value === "Referral" || event.target.value === "Other") {
      setShowReferralOrOther(true);
    } else {
      setShowReferralOrOther(false);
    }
  };

  if (state.succeeded) {
    setTimeout(() => {
      window.location.href = "/thank-you";
    }, 300);
  }
  return (
    <form onSubmit={handleSubmit} className={`flex flex-col`}>
      <div className={`flex flex-col lg:flex-row mb-4`}>
        {/* Name Input */}
        <div className={`flex-1 lg:pr-2 mb-4 lg:mb-0`}>
          <label htmlFor="name" className={labelClass}>
            Name
            <span className="text-[#FF0000]">*</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className={inputClass}
            placeholder={`Name*`}
            required
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
        </div>

        {/* Email Input */}
        <div className={`flex-1 lg:pl-2`}>
          <label htmlFor="company" className={labelClass}>
            Company/Organization
            <span className="text-[#FF0000]">*</span>
          </label>
          <input
            type="text"
            name="company"
            id="company"
            className={inputClass}
            required
            placeholder={`Company/Organization*`}
          />
          <ValidationError
            prefix="Company"
            field="company"
            errors={state.errors}
          />
        </div>
      </div>
      <div className={`flex flex-col mb-4`}>
        {/* Location Input */}
        <label htmlFor="location" className={labelClass}>
          Where are you located?
          <span className="text-[#FF0000]">*</span>
        </label>
        <input
          type="text"
          name="location"
          id="location"
          className={inputClass}
          placeholder={`City, State*`}
          required
        />
        <ValidationError
          prefix="Location"
          field="location"
          errors={state.errors}
        />
      </div>
      <div className={`flex flex-col lg:flex-row mb-4`}>
        {/* Email Input */}
        <div className={`flex-1 lg:pr-2 mb-4 lg:mb-0`}>
          <label htmlFor="email" className={labelClass}>
            Email
            <span className="text-[#FF0000]">*</span>
          </label>
          <input
            type="text"
            name="email"
            id="email"
            className={inputClass}
            placeholder={`Email*`}
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>

        {/* Phone Input */}
        <div className={`flex-1 lg:pl-2`}>
          <label htmlFor="phone" className={labelClass}>
            Phone Number
            <span className="text-[#FF0000]">*</span>
          </label>
          <input
            type="text"
            name="phone"
            id="phone"
            className={inputClass}
            required
            placeholder={`Phone Number*`}
          />
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />
        </div>
      </div>
      <div className={`flex flex-col mb-4`}>
        {/* Checkboxes for Services */}
        <label className={labelClass}>Service(s) inquiring about</label>
        <div className={`flex flex-col lg:flex-row`}>
          <label className={checkBoxLabelClass}>
            <input
              type="checkbox"
              name="services"
              value="Events"
              className={checkBoxClass}
            />
            Events
          </label>
          <label className={checkBoxLabelClass}>
            <input
              type="checkbox"
              name="services"
              value="Destination Management"
              className={checkBoxClass}
            />
            Destination Management
          </label>
          <label className={checkBoxLabelClass}>
            <input
              type="checkbox"
              name="services"
              value="Communication/PR"
              className={checkBoxClass}
            />
            Communication/PR
          </label>
          <label className={checkBoxLabelClass}>
            <input
              type="checkbox"
              name="services"
              value="Creative/Graphic Design"
              className={checkBoxClass}
            />
            Creative/Graphic Design
          </label>
          <label className={checkBoxLabelClass}>
            <input
              type="checkbox"
              name="services"
              value="Venue Consulting"
              className={checkBoxClass}
            />
            Venue Consulting
          </label>
          <label className={checkBoxLabelClass}>
            <input
              type="checkbox"
              name="services"
              value="Other"
              className={checkBoxClass}
            />
            Other
          </label>
        </div>
      </div>
      <div className={`flex flex-col mb-4`}>
        {/* Date Input */}
        <label className={labelClass}>Date of Event</label>
        <input
          type="date"
          name="date"
          id="date"
          className={inputClass}
          placeholder={`Select a date`}
        />
        <ValidationError prefix="Date" field="date" errors={state.errors} />
      </div>
      <div className={`flex flex-col mb-4`}>
        <label className={labelClass}>
          Please share more details, the more the better!
          <span className="text-[#FF0000]">*</span>
        </label>
        <textarea
          name="describe"
          id="describe"
          placeholder={`Type of Event, Number of Guests, Venue, Branding, Brochure Design, Social Media, PR, etc.`}
          rows={4}
          className={textAreaClass}
        />
        <ValidationError
          prefix="Description"
          field="describe"
          errors={state.errors}
        />
      </div>
      <div className={`flex flex-col mb-4`}>
        {/* Radio Buttons for "How did you hear about us?" */}
        <label className={labelClass}>How did you hear about us?</label>
        <div>
          <label className={radioLabelClass}>
            <input
              type="radio"
              name="heardAboutUs"
              value="Referral"
              className={`mr-2`}
              onChange={handleRadioChange}
            />
            Referral
          </label>
          <label className={radioLabelClass}>
            <input
              type="radio"
              name="heardAboutUs"
              value="Website"
              className={`mr-2`}
              onChange={handleRadioChange}
            />
            Website
          </label>
          <label className={radioLabelClass}>
            <input
              type="radio"
              name="heardAboutUs"
              value="Social media"
              className={`mr-2`}
              onChange={handleRadioChange}
            />
            Social media
          </label>
          <label className={radioLabelClass}>
            <input
              type="radio"
              name="heardAboutUs"
              value="Other"
              className={`mr-2`}
              onChange={handleRadioChange}
            />
            Other
          </label>
        </div>
      </div>
      {showReferralOrOther && (
        <div className={`flex flex-col mb-4`}>
          {/* Optional Text Field for Referral or Other */}
          <label className={labelClass}>
            If referral, by whom? If other, how?
          </label>
          <input
            type="text"
            name="referralOrOther"
            id="referralOrOther"
            className={inputClass}
            placeholder={``}
          />
        </div>
      )}
      <div className={`mt-4`}>
        <button
          type="submit"
          disabled={state.submitting}
          className={`w-full rounded-full border-transparent border-[1px] bg-primary py-2 px-14 text-black font-sans transition duration-300 ease-in-out hover:shadow-lg hover:border-black hover:bg-primary hover:text-black`}
        >
          Submit
        </button>
      </div>
    </form>
  );
}
function App() {
  return <ContactForm />;
}
export default App;
