import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Events } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import Image from "next/image";
import Link from "next/link";
import { Content } from "components/Content";
import styles from "./Events.module.css";

interface EventsProps extends Page_Flexiblecontent_Blocks_Events {
  className?: string;
}

const Events = ({ className, title, eventItem, cta }: EventsProps) => {
  const isArched = cta?.url ? true : false;

  return (
    <div
      className={`${className} my-4 pb-4 xl:my-10 xl:pb-10 ${
        isArched ? "mt-0 md:mt-20" : ``
      }`}
    >
      <div
        className={`mx-auto max-w-[1500px] px-8 pr-0 md:pr-8 lg:px-16 text-center ${
          isArched ? `flex flex-col lg:flex-row` : ``
        }`}
      >
        <div
          className={`relative flex ${
            isArched ? `lg:flex-col lg:mr-20 xl:mr-40` : ``
          } items-center`}
        >
          <h2
            className={`my-10 w-full min-w-[240px] md:min-w-auto max-w-[10rem] md:max-w-[25rem] text-left font-serif text-[1.3rem] md:text-[1.7rem] lg:text-[2.1rem] font-semibold tracking-wider leading-none`}
          >
            <Content
              content={title}
              className={`${isArched ? `lg:flex lg:flex-col` : ``}`}
            />
          </h2>
          <div
            className={`h-[1px] bg-black ${
              isArched ? `block lg:hidden flex-1` : `w-full ml-2 md:ml-10`
            }`}
          ></div>
        </div>
        <div
          className={`${styles.scrollBars} overflow-x-scroll md:overflow-visible pr-5 md:pr-0`}
        >
          <div
            className={`pb-10 md:pb-0 md:min-w-full ${
              isArched
                ? `gap-4 lg:gap-6 flex flex-row md:grid md:grid-cols-4`
                : `flex flex-row md:grid md:grid-cols-4 gap-0`
            }`}
          >
            {eventItem?.map((item, index) => (
              <div
                className="flex flex-col items-center mr-4 md:mr-0 group"
                key={`${index}`}
              >
                <div
                  className={`relative overflow-hidden ${
                    isArched
                      ? `w-[9rem] h-[9rem] md:w-full md:h-full rounded-t-full min-h-[210px] max-h-[210px] xl:min-h-[315px] mb-6`
                      : `w-[8rem] h-[8rem] md:w-[10rem] md:h-[10rem] xl:w-[13rem] xl:h-[13rem] rounded-full mb-10`
                  }`}
                >
                  <Link href={item?.itemLink ?? ""}>
                    <Image
                      src={item?.itemImage?.sourceUrl ?? ""}
                      alt={item?.itemImage?.altText ?? ""}
                      // width={item?.itemImage?.mediaDetails?.width ?? 0}
                      // height={item?.itemImage?.mediaDetails?.height ?? 0}
                      fill
                      className={`${
                        isArched ? `h-full w-full object-cover` : ``
                      }`}
                    />
                  </Link>
                </div>
                <Link href={item?.itemLink ?? ""}>
                  <h3
                    className={`font-sans ${
                      isArched ? `text-sm` : `text-[.89rem]`
                    } font-bold uppercase underline-offset-4 decoration-2 tracking-[0.125rem] max-w-[7rem] md:max-w-full text-center md:px-4 lg:px-0`}
                  >
                    <span
                      className={`pb-1 border-b-2 border-transparent group-hover:underline transition-all duration-300 xl:whitespace-nowrap`}
                    >
                      {item?.itemTitle}
                    </span>
                  </h3>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

Events.fragments = {
  pageEntry: gql`
    fragment EventsPageFragment on Page_Flexiblecontent_Blocks_Events {
      __typename
      title
      eventItem {
        itemTitle
        itemLink
        itemImage {
          ...MediaItemFragment
        }
      }
      cta {
        url
        title
        target
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
  serviceEntry: gql`
    fragment EventsServiceFragment on Service_Flexiblecontent_Blocks_Events {
      __typename
      title
      eventItem {
        itemTitle
        itemLink
        itemImage {
          ...MediaItemFragment
        }
      }
      cta {
        url
        title
        target
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
