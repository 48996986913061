import { gql } from "@apollo/client";
import { Content } from "components/Content";
import { Page_Flexiblecontent_Blocks_TextBlock } from "graphql";

interface TextBlockProps extends Page_Flexiblecontent_Blocks_TextBlock {
  className?: string;
}

const TextBlock = ({ className, title, content }: TextBlockProps) => {
  return (
    <section className={`${className}`}>
      <div className="mx-auto max-w-[1500px] px-10 lg:px-16">
        <h2
          className={`mb-5 md:mb-10 font-serif text-[1.3rem] md:text-[2.34375rem] font-semibold italic`}
        >
          <Content content={title} />
        </h2>
        <Content
          content={content}
          className={`text-[1rem] md:text-[1.171875rem] leading-[1.7rem]`}
        />
      </div>
    </section>
  );
};

export default TextBlock;

TextBlock.fragments = {
  pageEntry: gql`
    fragment TextBlockPageFragment on Page_Flexiblecontent_Blocks_TextBlock {
      __typename
      title
      content
    }
  `,
  serviceEntry: gql`
    fragment TextBlockServiceFragment on Service_Flexiblecontent_Blocks_TextBlock {
      __typename
      title
      content
    }
  `,
};
